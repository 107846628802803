import { ref, computed } from 'vue'

export var collapsed = ref(false)

if (window.innerWidth < 900) {
  collapsed = ref(true);
}


export const toggleSidebar = (bool) => { collapsed.value = (bool != undefined && !bool) ? SIDEBAR_WIDTH_COLLAPSED : !collapsed.value }

export const SIDEBAR_WIDTH = '100%'
export const SIDEBAR_WIDTH_COLLAPSED = '72px'
export const sidebarWidth = computed(
  () => `${collapsed.value ? SIDEBAR_WIDTH_COLLAPSED : SIDEBAR_WIDTH}`
)