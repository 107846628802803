import { TokenService } from '@/services/storage.service'
import axios from 'axios';
import store from "@/store";
import { ref } from "vue"

const baseUrl = process.env.VUE_APP_DRIVEY_BASE_URL;
let accessToken = TokenService.getToken();
const settings = { headers: {
  'Content-Type': 'application/json',
  'Accept': 'application/json',
  'Authorization': `Bearer ${accessToken}`,
  },
  method: 'POST',
}
const getSettings = { headers: {
  'Content-Type': 'application/json',
  'Accept': 'application/json',
  'Authorization': `Bearer ${accessToken}`,
  },
  method: 'GET',
}

const Drivey = {
  //-------------------------------------------------------------------
  // Methods
  //-------------------------------------------------------------------
  /*methods: {
    /**
     * @description Create parameters for url
     * @param {string} url
     * @param {object} params
     * @returns {string}
     */
    urlParams(url, params, exclude, custom_arg) {
      const path = new URL(`${baseUrl}/api/v1` + url);
      for (const key in params) {
        if (params[key] !== undefined && exclude !== key) {
          path.searchParams.append(key, params[key]);
        }
      }
      if(custom_arg !== undefined){
        const arg = custom_arg.toString()
        return path.toString() + arg.replace(/,/g, "");
      }else {
        return path.toString();
      }
    }, 

    async login(payload = { email: '', password: '', auth_token: '', provider_id: '', account_id: '' }) {
        //const baseUrl = process.env.VUE_APP_DRIVEY_BASE_URL
        const { email, password, auth_token, provider_id, account_id } = payload;

        var settings = { headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json',
          },
          method: 'POST',
        }
console.log(email);
console.log(password);
        var emailEncode = encodeURIComponent(email)
        var passwordEncode = encodeURIComponent(password)
console.log(emailEncode);
console.log(passwordEncode);
        store.commit('IS_LOADING', true);
        var response = await fetch(`${baseUrl}/api/v1/user/login?auth_token=${auth_token}&provider_id=${provider_id}&account_id=${account_id}&email=${emailEncode}&password=${passwordEncode}`, settings)

        if(!response.ok) {
          store.commit('IS_LOADING', false);
          throw Error('There was an error logging in')
        }

        var responseBody = await response.json()
        store.commit('IS_LOADING', false);
        return responseBody
    }, 

    async signup(payload = {email: '', password: '', name: '', terms: '', marketing: '' }) {
        //const baseUrl = process.env.VUE_APP_DRIVEY_BASE_URL
        const { email, password, name, terms, marketing } = payload;

        var settings = { headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json',
          },
          method: 'POST',
        }

        var emailEncode = encodeURIComponent(email)
        var passwordEncode = encodeURIComponent(password)
        var nameEncode = encodeURIComponent(name)
        store.commit('IS_LOADING', true);
        var response = await fetch(`${baseUrl}/api/v1/user/signup?email=${emailEncode}&password=${passwordEncode}&name=${nameEncode}&terms=${terms}&email_marketing=${marketing}`, settings)

        if(!response.ok) {
            throw Error('There was an error signing up.')
        }

        var responseBody = await response.json()

        /*if(!responseBody || responseBody.error) {
            throw Error('There was an error signing up.')
        }*/
        store.commit('IS_LOADING', false);
        return responseBody
    }, 

    async createProviderAccount(providerId, accountId, token) {

        //const baseUrl = process.env.VUE_APP_DRIVEY_BASE_URL

        var settings = { headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json',
          },
          method: 'POST',
        }
        store.commit('IS_LOADING', true);
        var response = await fetch(`${baseUrl}/api/v1/user/create?auth_token=${token}&provider_id=${providerId}&account_id=${accountId}`, settings)

        console.log("create provider response", response)
        if(!response.ok) {
            store.commit('IS_LOADING', false);
            throw Error('There was an error creating a provider account.')
        }
        var responseBody = await response.json()
        console.log("create provider response body", responseBody)
        store.commit('IS_LOADING', false);
        return responseBody

    }, 

    async addProviderAccount(providerId, accountId, token, email) {
        /*const baseUrl = process.env.VUE_APP_DRIVEY_BASE_URL;
        let accessToken = TokenService.getToken();

        var settings = { headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json',
            'Authorization': `Bearer ${accessToken}`,
          },
          method: 'POST',
        }*/
        store.commit('IS_LOADING', true);
        var response = await fetch(`${baseUrl}/api/v1/user/add_account?auth_token=${token}&provider_id=${providerId}&account_id=${accountId}&email=${email}`, settings)

        if(!response.ok) {
            store.commit('IS_LOADING', false);
            throw Error('There was an error adding a provider account.')
        }
        var responseBody = await response.json()
        store.commit('IS_LOADING', false);
        return responseBody

    }, 

    async getUserInfo() {
        /*const baseUrl = process.env.VUE_APP_DRIVEY_BASE_URL;
        let accessToken = TokenService.getToken();
console.dir(accessToken);

        var settings = { headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                'Authorization': `Bearer ${accessToken}`,
            },
            method: 'GET',
        }*/
        store.commit('IS_LOADING', true);
        let response = await fetch(`${baseUrl}/api/v1/user/info`, getSettings).then(resp => {
          console.dir(resp);
          return resp.json()
        }).catch(() => {
          store.commit('IS_LOADING', false);
          throw Error('There was an error signing in.')
        })
        return response;
        /*if(!response.ok) {
            if(response.status === 401) {
              alert('no log')
              throw Error('401 Unauthenticated')
            }
            store.commit('IS_LOADING', false);
            throw Error('There was an error adding a provider account.')
        }
        var responseBody = await response.json()
        store.commit('IS_LOADING', false);
        return responseBody*/
    }, 

    async addOther (payload = {service: '', account_id:'', access_key: '', access_secret: '', host: '', user: '', pass: '' }) {
      const baseUrl = process.env.VUE_APP_DRIVEY_BASE_URL;
      const { service, account_id, access_key, access_secret, host, user, pass } = payload;
      let token, response;
      store.commit('IS_LOADING', true);
      token = (access_key) ? access_key.replaceAll("+", "%2B") : null;
      
      response = await fetch(`${baseUrl}/api/v1/oauth/add_other?service=${service}&access_key=${token}&access_secret=${access_secret}&account_id=${account_id}&host=${host}&user=${user}&password=${encodeURIComponent(pass)}`, settings);
      
      if(!response.ok || response.code == 'Set Error') {
        store.commit('IS_LOADING', false);
          throw Error('There was an error adding a provider account.')
      }
      var responseBody = await response.json()
      store.commit('IS_LOADING', false);
      return responseBody
    }, 

    async addAccount(payload = { service: '', name: '', account_id: '', access_key: '', access_secret: '', host: '', user: '', pass: '' }) {
      const { service, account_id, name, access_key, access_secret, host, user, pass } = payload;
      
      
      store.commit('IS_LOADING', true);
      //let accessToken = TokenService.getToken();
      if(access_key || host) {
        console.log('---access token---')
        await this.addOther({service: service, name: name, access_key: access_key, access_secret: access_secret, account_id: account_id, host: host, user: user, pass: encodeURIComponent(pass)}).then(async resp => {
          //let resBody = await resp.json()
          let resBody = resp;
          store.commit('IS_LOADING', false);
          console.dir(resBody);
          console.log('---res body1---')
          return resBody;
        }).catch(err => {
          console.dir(err)
          store.commit('IS_LOADING', false);
          throw Error('There was an error adding a provider account.')
        })
      } else {
        console.log('-service-', service)
        await this.ssoLogin({service: service, type: 3 }).then(async resp => {
        }).catch(err => {
          console.dir(err)
          store.commit('IS_LOADING', false);
          throw Error('There was an error adding a provider account.')
        });
        /*.then(async resp => {
          const { access_token, provider_id } = resp;
          //let resBody = await resp.json()
          store.commit('IS_LOADING', false);
          console.dir(resp);
          console.log(access_token);
          console.log(provider_id)
          console.log('---res body2---')          
          let res = await this.addDrive({access_token: access_token, account_id: account_id, provider_id: provider_id})
          alert('sso response')
          let resBody = await res.json()
          store.commit('IS_LOADING', false);
          console.dir(resBody);
          console.log('---res body3---')
          return resBody;
        }).catch(err => {
          console.dir(err)
          store.commit('IS_LOADING', false);
          throw Error('There was an error adding a provider account.')
        });  */      
      }
    }, 

    async getDrives() {
      /*const baseUrl = process.env.VUE_APP_DRIVEY_BASE_URL;
      let accessToken = TokenService.getToken();

      var settings = { headers: {
              'Content-Type': 'application/json',
              'Accept': 'application/json',
              'Authorization': `Bearer ${accessToken}`,
          },
          method: 'POST',
      }*/
      store.commit('IS_LOADING', true);
      var response = await fetch(`${baseUrl}/api/v1/drives/list_drives`, settings).catch(err => {
        console.dir(err);
      })
      //console.dir(response.json())
      console.log('---getdrives')

      if(!response.ok) {
          store.commit('IS_LOADING', false);
          console.log('----------2')
          console.dir(response);
          if(response.status === 401) throw Error('401 Unauthenticated');
          else throw Error('There was an error fetching drives.')
      }
      var responseBody = response.json()
      store.commit('IS_LOADING', false);
      return responseBody
    }, 

    async removeDrive(id) {
      /*const baseUrl = process.env.VUE_APP_DRIVEY_BASE_URL;
      let accessToken = TokenService.getToken();

      var settings = { headers: {
              'Content-Type': 'application/json',
              'Accept': 'application/json',
              'Authorization': `Bearer ${accessToken}`,
          },
          method: 'POST',
      }*/
      store.commit('IS_LOADING', true);
      var response = await fetch(`${baseUrl}/api/v1/drives/remove_drive?id=${id}`, settings)

      if(!response.ok) {
          store.commit('IS_LOADING', false);
          throw Error('There was an error removing the requested account.')
      }
      var responseBody = await response.json()
      store.commit('IS_LOADING', false);
      return responseBody
    }, 

    async changeActive(id) {
      /*const settings = { headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/json',
        'Authorization': `Bearer ${accessToken}`,
        },
        method: 'POST',
      }*/
      store.commit('IS_LOADING', true);
      var response = await fetch(`${baseUrl}/api/v1/drives/change_active?drive_id=${id}`, settings)

      if(!response.ok) {
          store.commit('IS_LOADING', false);
          throw Error('There was an error changing the active account.')
      }
      var responseBody = await response.json()
      store.commit('IS_LOADING', false);
      return responseBody
    }, 

    async updateProfile(name, email, password) {
      /*const baseUrl = process.env.VUE_APP_DRIVEY_BASE_URL;
      let accessToken = TokenService.getToken();

      var settings = { headers: {
              'Content-Type': 'application/json',
              'Accept': 'application/json',
              'Authorization': `Bearer ${accessToken}`,
          },
          method: 'POST',
      }*/
      store.commit('IS_LOADING', true);
      var response = await fetch(`${baseUrl}/api/v1/user/update?name=${name}&email=${email}&password=${password}`, settings)

      if(!response.ok) {
          throw Error('There was an error updating your profile.')
      }
      var responseBody = await response.json()
      store.commit('IS_LOADING', false);
      return responseBody
    }, 

    async getTracking() {
      /*const baseUrl = process.env.VUE_APP_DRIVEY_BASE_URL;
      let accessToken = TokenService.getToken();

      var settings = { headers: {
              'Content-Type': 'application/json',
              'Accept': 'application/json',
              'Authorization': `Bearer ${accessToken}`,
          },
          method: 'POST',
      }*/
      store.commit('IS_LOADING', true);
      let response = await axios.get(`${baseUrl}/api/v1/tracking/get`, settings)

      if(response.status !== 200) {
          if(response.status == 401) {
            throw Error('401 Unauthenticated');
          }
          store.commit('IS_LOADING', false);
          throw Error('There was an error')
      }
      store.commit('IS_LOADING', false);
      return response
    }, 

    async fileList(payload = { path: '', service: '', param: '', drive_id: '', shared_drive_id: '', shared: '' }) {
      const { path, service, param, drive_id, shared_drive_id, shared } = payload;
      const queryString = window.location.search;
      let searchParams = new URLSearchParams(queryString);
      // let query;

      /*for (const [key, value] of Object.entries(param)) {
        searchParams = `&${key}=${value}`
      }*/

      const urlParams = searchParams.toString() //shared === true ? searchParams.toString() : (shared === false) ? '&shared=0' : ''
      //driveId = ${urlParams.indexOf('&shared=1') != -1 ? '&driveId=' + shared_drive_id : '&driveId=' + drive_id
      store.commit('IS_LOADING', true);
      console.log(shared_drive_id);
      console.log(param)
      console.log(shared);
console.dir(urlParams);
console.log(service)
console.log('---url params')
      /*const settings = { headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/json',
        'Authorization': `Bearer ${accessToken}`,
        },
        method: 'POST',
      }*/
      var response = await fetch(`${baseUrl}/api/v1/files/list?folder=${path}&service=${service}&driveId=${drive_id}&${urlParams}`, settings).catch(err => {  
        console.dir(err);
      });

      if(!response.ok) {
          store.commit('IS_LOADING', false);
          throw Error('There was an error listing your files.')
      }
      var responseBody = await response.json();
console.dir(responseBody)
      if(responseBody.code === "Retrieve Error") {
        store.commit('IS_LOADING', false);
        throw Error('There was an error listing your files.')
      }
      store.commit('IS_LOADING', false);
      return responseBody
    }, 

    async listEncrypted() {
      /*const baseUrl = process.env.VUE_APP_DRIVEY_BASE_URL;
      let accessToken = TokenService.getToken();

      var settings = { headers: {
          'Content-Type': 'application/json',
          'Accept': 'application/json',
          'Authorization': `Bearer ${accessToken}`,
        },
        method: 'GET',
      }*/
      store.commit('IS_LOADING', true);
      var response = await fetch(`${baseUrl}/api/v1/encrypt/list`, settings)

      if(!response.ok) {
          store.commit('IS_LOADING', false);
          throw Error('There was an error adding a provider account.')
      }
      var responseBody = await response.json()
      store.commit('IS_LOADING', false);
      return responseBody
    }, 

    async listFavorites() {
      /*const baseUrl = process.env.VUE_APP_DRIVEY_BASE_URL;
      let accessToken = TokenService.getToken();

      var settings = { headers: {
          'Content-Type': 'multipart/form-data',
          'Accept': 'multipart/form-data',
          'Authorization': `Bearer ${accessToken}`,
        },
        method: 'GET',
      }*/
      store.commit('IS_LOADING', true);
      var response = await fetch(`${baseUrl}/api/v1/favorite/list`, getSettings)
console.dir(response)
      if(!response.ok) {
          store.commit('IS_LOADING', false);
          throw Error('There was an error adding a provider account.')
      }
      var responseBody = await response.json()
      store.commit('IS_LOADING', false);
      return responseBody
    }, 

    async encryptGetKey() {
      /*const settings = { headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/json',
        'Authorization': `Bearer ${accessToken}`,
        },
        method: 'GET',
      }*/
      store.commit('IS_LOADING', true);
      let response = await axios.get(`${baseUrl}/api/v1/encrypt/getKey`, getSettings)

      if(response.status !== 200) {
        store.commit('IS_LOADING', false);
        throw Error('There was an error')
      }
      store.commit('IS_LOADING', false);
      return response
    }, 

    async uploadItem(payload = {service: '', file: '', path: ''}) {
      const { service, file, path, callback } = payload;
console.dir(service);
console.log('callback..')
      //const file = ref(null);
      const chunkSize = 1024 * 1024; // 1 MB
      const currentChunkIndex = 0;
      const uploadProgress = null;
      const error = null;
      const baseUrl = process.env.VUE_APP_DRIVEY_BASE_URL;
      let accessToken = TokenService.getToken();

      let chunks = [];
      let offset = 0;
      console.log('chonk it')
      while (offset < file.size) {
        console.dir(offset+chunkSize)
        let chunk = (offset + chunkSize > file.size) ? file.size : offset + chunkSize;
        console.log('----create chunks')
        chunks.push(file.slice(offset, chunk));
        offset += chunkSize;
      }

      let i = 0;
      let response;
      while(chunks.length >= 1) {
        console.log('while')
        console.log(chunks.length)
        //if(chunks.length) {
        const formData = new FormData();
        //let filePart = new File([chunk], `${file.name}.part`);
        formData.append('service', service)
        formData.set('is_last', chunks.length == 1);
        //console.dir(filePart);
        formData.append('file', chunks[i], `${file.name}.part`);
        formData.append('destination_path', path)
  
        var settings = {
          headers: {
            'Content-Type': 'multipart/form-data;',
            'Accept': 'application/octet-stream',
            'Authorization': `Bearer ${accessToken}`,
          },
          method: 'POST',
        }
        await axios.post(`${baseUrl}/api/v1/files/upload`, formData, settings).then(resp => {
          chunks.shift();
          i++
          console.log('----resp')
          if(chunks.length == 0 && resp != undefined) {
            console.dir(resp);
            console.log('----resp final')
            response = resp
          }
        })
      }
      if(response) return response;
    }, 

    async uploadItemFolder(payload = {service: '', file: '', path: ''}) {
      const { service, file, path } = payload;

      const baseUrl = process.env.VUE_APP_DRIVEY_BASE_URL;
      let accessToken = TokenService.getToken();

      const formData = new FormData();
      formData.append('service', service)
      formData.append('file', file)
      //formData.append('path', path)
      formData.append('destination_path', path)
      //formData.append('file_path', file.webkitRelativePath)

      var settings = {
        headers: {
          'Content-Type': 'application/json',
          'Accept': 'application/json',
          'Authorization': `Bearer ${accessToken}`,
        },
        method: 'POST',
      }
      store.commit('IS_LOADING', true);
      var response = axios.post(`${baseUrl}/api/v1/files/upload_folder`, formData, settings)
      store.commit('IS_LOADING', false);
      return response
    }, 

    async encryptUpload(payload = {file: '', drive_id: '', path: ''}) {
      const { file, drive_id, path } = payload;
      const baseUrl = process.env.VUE_APP_DRIVEY_BASE_URL;
      let accessToken = TokenService.getToken();

      console.log('passing file', file)
      const formData = new FormData();
      formData.append('file', file)
      formData.append('drive_id', drive_id)
      //formData.append('path', path)
      formData.append('destination_path', path)
      formData.append('file_path', file.webkitRelativePath)

      var settings = {
        headers: {
          'Content-Type': 'multipart/form-data',
          'Accept': 'multipart/form-data',
          'Authorization': `Bearer ${accessToken}`,
        },
        method: 'POST',
      }
      store.commit('IS_LOADING', true);
      var response = axios.post(`${baseUrl}/api/v1/encrypt/upload`, formData, settings)
      store.commit('IS_LOADING', false);
      return response
    }, 

    async encryptExisting(payload = {asset_id:'', drive_id:''}) {
      const { asset_id, drive_id } = payload;
      var settings = { headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/json',
        'Authorization': `Bearer ${accessToken}`,
      },
        method: 'POST',
      }
      store.commit('IS_LOADING', true);
      var response = await fetch(`${baseUrl}/api/v1/encrypt/existing?file_id=${asset_id}&drive_id=${drive_id}`, settings)

      if(!response.ok) {
        store.commit('IS_LOADING', false);
        throw Error('There was an error adding a provider account.')
      }
      var responseBody = await response.json()
      store.commit('IS_LOADING', false);
      return responseBody

    }, 

    async favorite(payload = {asset_id:'', drive_id:''}) {
      const { asset_id, drive_id } = payload;
      var settings = { headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/json',
        'Authorization': `Bearer ${accessToken}`,
      },
        method: 'POST',
      }
      store.commit('IS_LOADING', true);
      var response = await fetch(`${baseUrl}/api/v1/favorite/add?asset_id=${asset_id}&drive_id=${drive_id}&toggle=1`, settings)

      if(response.status !== 200) {
        store.commit('IS_LOADING', false);
        throw Error('There was an error favoriting the item')
      }
      store.commit('IS_LOADING', false);
      return response
    }, 

    async unfavorite(payload = {asset_id:'', drive_id:''}) {
      const { asset_id, drive_id } = payload;
      var settings = { headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/json',
        'Authorization': `Bearer ${accessToken}`,
      },
        method: 'POST',
      }
      store.commit('IS_LOADING', true);
      var response = await fetch(`${baseUrl}/api/v1/favorite/remove?asset_id=${asset_id}&drive_id=${drive_id}&toggle=0`, settings)

      if(response.status !== 200) {
        store.commit('IS_LOADING', false);
        throw Error('There was an error unfavoriting the item')
      }
      store.commit('IS_LOADING', false);
      return response
    }, 

    async unencryptFile(payload = {asset_id:'', drive_id:''}) {
      const { asset_id, drive_id } = payload;
      var settings = { headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/json',
        'Authorization': `Bearer ${accessToken}`,
      },
        method: 'POST',
      }
      store.commit('IS_LOADING', true);
      var response = await fetch(`${baseUrl}/api/v1/encrypt/download_unencrypted?encrypt_id=${asset_id}&drive_id=${drive_id}`, settings)

      if(response.status !== 200) {
        store.commit('IS_LOADING', false);
        throw Error('There was an error decrypting the item')
      }
      store.commit('IS_LOADING', false);
      return response
    }, 

    async downloadEncypted(payload = {asset_id:'', drive_id:''}) {
      const { asset_id, drive_id } = payload;
      var settings = { headers: {
          'Authorization': `Bearer ${accessToken}`,
        },
        method: 'POST',
        responseType: 'blob',
      }
      store.commit('IS_LOADING', true);
      var response = await fetch(`${baseUrl}/api/v1/encrypt/download?encrypt_id=${asset_id}&drive_id=${drive_id}`, settings)

      if(response.status !== 200) {
        store.commit('IS_LOADING', false);
        throw Error('There was an error downloading the item')
      }
      store.commit('IS_LOADING', false);
      return response
    }, 

    async decrypt(payload = {asset_id:'', drive_id:''}) {
      const { asset_id, drive_id } = payload;
      var settings = { headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/json',
        'Authorization': `Bearer ${accessToken}`,
      },
        method: 'POST',
      }
      store.commit('IS_LOADING', true);
      var response = await fetch(`${baseUrl}/api/v1/encrypt/decrypt?encrypt_id=${asset_id}&drive_id=${drive_id}`, settings)

      if(!response.ok) {
        store.commit('IS_LOADING', false);
        throw Error('There was an error adding a provider account.')
      }
      var responseBody = await response.json()
      store.commit('IS_LOADING', false);
      return responseBody
    }, 

    async downloadBackup(backup_id) {
      var settings = { headers: {
          'Content-Type': 'application/json',
          'Accept': 'application/json',
          'Authorization': `Bearer ${accessToken}`,
        },
        method: 'POST',
      }
      store.commit('IS_LOADING', true);
      var response = await axios.post(`${baseUrl}/api/v1/backup/download?backup_id=${backup_id}`, [], settings)

      if(response.status !== 200) {
        store.commit('IS_LOADING', false);
        throw Error('There was an error downloading the backup')
      }
      store.commit('IS_LOADING', false);
      return response
    }, 

    async renameItem(payload = {asset_id: '', name: '', service: ''}) {
      const {asset_id, name, service} = payload;

      var settings = { headers: {
          'Content-Type': 'application/json',
          'Accept': 'application/json',
          'Authorization': `Bearer ${accessToken}`,
        },
        method: 'POST',
      }
      store.commit('IS_LOADING', true);
      var response = await fetch(`${baseUrl}/api/v1/files/rename?location=${asset_id}&new_location=${name}&service=${service}`, settings)

      if(!response.ok) {
          store.commit('IS_LOADING', false);
          throw Error('There was an error adding a provider account.')
      }
      var responseBody = await response.json()
      store.commit('IS_LOADING', false);
      return responseBody
    }, 

    async downloadItem(payload = {service: '', asset_id: ''}) {
      const {service, asset_id} = payload;

      var settings = { headers: {
          'Content-Type': 'application/json',
          'Accept': 'application/json',
          'Authorization': `Bearer ${accessToken}`,
        },
        method: 'POST',
        responseType: 'blob',
      }
      store.commit('IS_LOADING', true);
      var response = await axios.post(`${baseUrl}/api/v1/files/download?file_id=${asset_id}&service=${service}`, [], settings)

      if(response.status !== 200) {
        store.commit('IS_LOADING', false);
        throw Error('There was an error downloading the file')
      }
      store.commit('IS_LOADING', false);
      return response
    }, 

    async deleteItem(payload = {service: '', asset_id: ''}) {
      const {service, asset_id} = payload;

      var settings = { headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/json',
        'Authorization': `Bearer ${accessToken}`,
        },
        method: 'POST',
      }
      store.commit('IS_LOADING', true);
      var response = await fetch(`${baseUrl}/api/v1/files/delete?service=${service}&file_id=${asset_id}`, settings)

      if(!response.ok) {
          store.commit('IS_LOADING', false);
          throw Error('There was an error adding a provider account.')
      }
      var responseBody = await response.json()
      console.log('deleted item', responseBody)
      store.commit('IS_LOADING', false);
      return responseBody
    }, 

    async copyItem(payload = { drive_id: '', dest_drive_id: '', bucket: '', asset_id: '', dest_id: ''}) {
      const { drive_id, dest_drive_id, bucket, asset_id, dest_id } = payload;
      store.commit('IS_LOADING', true);
      var response = await fetch(`${baseUrl}/api/v1/files/copy?drive_id=${drive_id}&dest_drive_id=${dest_drive_id}&dest_bucket=${bucket}&asset_id=${asset_id}&dest_id=${dest_id}`, settings)

      if(!response.ok) {
          store.commit('IS_LOADING', false);
          throw Error('There was an error adding a provider account.')
      }
      var responseBody = await response.json()
      console.log('copied item', responseBody)
      store.commit('IS_LOADING', false);
      return responseBody
    }, 

    async moveItem(payload = { drive_id: '', dest_drive_id: '', bucket: '', asset_id: '', dest_id: ''}) {
      const {drive_id, dest_drive_id, bucket, asset_id, dest_id} = payload;
      store.commit('IS_LOADING', true);
      var response = await fetch(`${baseUrl}/api/v1/files/move?drive_id=${drive_id}&asset_id=${asset_id}&dest_drive_id=${dest_drive_id}&dest_bucket=${bucket}&dest_id=${dest_id}`, settings)

      if(!response.ok) {
          store.commit('IS_LOADING', false);
          throw Error('There was an error adding a provider account.')
      }
      var responseBody = await response.json()
      console.log('renamed item', responseBody)
      store.commit('IS_LOADING', false);
      return responseBody
    }, 

    async listBackups(payload) {
      //const url = this.urlParams('/backup/list', payload)
      //alert(url)
      let url = this.urlParams('/backup/list', payload)
      store.commit('IS_LOADING', true);
      /*var settings = { headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/json',
        'Authorization': `Bearer ${accessToken}`,
      },
      method: 'POST',
    }*/
      var response = await fetch(url, settings);

      if (!response.ok) {
        store.commit('IS_LOADING', false);
        throw Error("There was an error listing Drivey backups.");
      }
      var responseBody = await response.json();
      store.commit('IS_LOADING', false);
      return responseBody;
    }, 

    async addBackUps(payload) {
      console.log('bp payload ', payload)
      const url = this.urlParams('/backup/create', payload)
      console.dir(url)
      var response = await fetch(url, settings);

      if (!response.ok) {
        store.commit('IS_LOADING', false);
        throw Error("There was an error adding Drivey backups.");
      }
      var responseBody = await response.json();
      console.dir(responseBody)
      return responseBody;
    }, 

    async addBackUpsFiles(payload) {

      const url = this.urlParams('/backup/add_files', payload, 'files', payload.files)
      store.commit('IS_LOADING', true);
      var response = await fetch(url, settings);

      if (!response.ok) {
        store.commit('IS_LOADING', false);
        throw Error("There was an error adding Drivey backups.");
      }
      var responseBody = await response.json();
      store.commit('IS_LOADING', false);
      return responseBody;

    }, 

    async pauseBackUps(id) {
      store.commit('IS_LOADING', true);
      var response = await fetch(`${baseUrl}/api/v1/pause?backup_id=${id}`, settings)
      if(!response.ok) {
          store.commit('IS_LOADING', false);
          throw Error('There was an error logging in')
      }
      var responseBody = await response.json()
      if(!responseBody || responseBody.error) {
          store.commit('IS_LOADING', false);
          throw Error('There was an error logging in')
      }
      store.commit('IS_LOADING', false);
      return responseBody
    }, 

    async resumeBackUps(id) {
      store.commit('IS_LOADING', true);
      var response = await fetch(`${baseUrl}/api/v1/resume?backup_id=${id}`, settings)

      if(!response.ok) {
          store.commit('IS_LOADING', false);
          throw Error('error resume back up')
      }
      var responseBody = await response.json()
      if(!responseBody || responseBody.error) {
          store.commit('IS_LOADING', false);
          throw Error('error resume back up')
      }
      store.commit('IS_LOADING', false);
      return responseBody
    }, 

    async deleteBackUps(payload) {
      const url = this.urlParams('/backup/delete', payload)
      store.commit('IS_LOADING', true);
      var response = await fetch(url, settings);

      if (!response.ok) {
        store.commit('IS_LOADING', false);
        throw Error("There was an error adding Drivey backups.");
      }
      var responseBody = await response.json();
      store.commit('IS_LOADING', false);
      return responseBody;
    }, 

    async createFolder(payload = { service:'', name:'', path:'' }) {
      const { service, name, path } = payload;
      const baseUrl = process.env.VUE_APP_DRIVEY_BASE_URL;
      let accessToken = TokenService.getToken();
      var settings = {
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: `Bearer ${accessToken}`,
        },

        method: "POST",
      };
      store.commit('IS_LOADING', true);
      var response = await fetch(`${baseUrl}/api/v1/files/create_folder?service=${service}&folder_name=${name}&destination_path=${path}`, settings)

      if(!response.ok) {
          store.commit('IS_LOADING', false);
          throw Error('There was an error adding a provider account.')
      }
      var responseBody = await response.json()
      console.log('deleted item', responseBody)
      store.commit('IS_LOADING', false);
      return responseBody
    }, 

    async addShortcuts(payload) {

      const url = this.urlParams('/shortcuts/add', payload)
      store.commit('IS_LOADING', true);
      var response = await fetch(url, settings);

      if (!response.ok) {
        store.commit('IS_LOADING', false);
        throw Error("There was an error adding Drivey backups.");
      }
      var responseBody = await response.json();
      store.commit('IS_LOADING', false);
      return responseBody;

    }, 

    async removeShortcuts(payload) {

      const url = this.urlParams('/shortcuts/remove', payload)
      //store.commit('IS_LOADING', true);
      var response = await fetch(url, settings);

      if(!response.ok) {
        //  store.commit('IS_LOADING', false);
          throw Error('There was an error removing the shortcut')
      }
      var responseBody = await response.json()
      if(!responseBody || responseBody.error) {
         // store.commit('IS_LOADING', false);
          throw Error('There was an error remove the shortcut')
      }
      store.commit('IS_LOADING', false);
      return responseBody
    }, 

    async listShortcuts() {
      //store.commit('IS_LOADING', true);
      /*var settings = { headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/json',
        'Authorization': `Bearer ${accessToken}`,
      },
      method: 'GET',
    }*/
      var response = await axios.get(`${baseUrl}/api/v1/shortcuts/list`, getSettings)
      console.dir(response);
      console.log('----kl ist shortcuts')
      if(!response.ok) {
          //store.commit('IS_LOADING', false);
          throw Error('There was an error fetching results')
      }
      var responseBody = await response.json()
      if(!responseBody || responseBody.error) {
          //store.commit('IS_LOADING', false);
          throw Error('There was an error fetching results')
      }
      //store.commit('IS_LOADING', false);
      return responseBody
    }, 


    async search(value, params) {
      store.commit('IS_LOADING', true);
      await fetch(`${baseUrl}/api/v1/search/drivey?search=${value}${params !== undefined && params !== null && params.length ? '&services=' + params.toString() : ''}`, settings).then((resp) => {  
        console.dir(resp)
        //store.commit('IS_LOADING', false);
        return resp;
      }).catch(err => {
        //store.commit('IS_LOADING', false);
        return err;
      });      
    }, 

    async ssoLogin(payload = {service:'', type: 1}) {
      const { service, type } = payload;
      //const obj = this;
      await axios.get(`${baseUrl}/api/v1/oauth/link?service=${service}&type=${type}`, settings).then(async resp => {
        /*if(!state.isChromeExt) window.location = resp.data
        else {
          console.dir(resp.data)
          let win = window.open(resp.data, "Drivey", "fullscreen=no")
          let int = setInterval(function() {
            console.dir(win)
            if(win.location && win.location.href && win.location.href.indexOf('&token') != -1) {
              console.log(win.location.href);
              token = win.location.href.slice(win.location.href.indexOf('&token=' +7), win.location.href.indexOf('&refresh'))
            }
          }, 500)
          if(token) {
            clearInterval(int)
            win.close()
          }*/
          let win = window.open(resp.data, "Drivey", "fullscreen=no,height=400,width=400,location=no,status=no,titlebar=no,toolbar=no,menubar=no,resizable=no")
          let res = null;
          //let step = 1;
          //let params, token, service, account_id;
          //return await new Promise((resolve)  => {
            let int = setInterval(function() {
              console.dir(win.location);            
              
              try {
                //console.dir(params);
                if(win.location && win.location.href.indexOf('drivey') != -1) {
                  win.close();
                  store.commit('IS_LOADING', false);
                  res = service;
                  // console.log(win.location.search);
                  // params = new URLSearchParams(win.location.search);
                  // console.dir(params);
                  // console.log('----params');
                }
                /*if(win.location && win.location.href && win.location.href.indexOf(domain) != -1) {
                  console.log('---redirect');
                  win.location.href = resp.data;
                  step = 2;
                } else if(step == 2 && params) {
                */
                /*if(params) {
                  console.log('---token');
                  console.log(win.location);
                  token = params.get('token');
                  service = params.get('service');
                  account_id = params.get('account_id');
                  console.dir(token);
                  //store.commit('IS_LOADING', false);
                  // TokenService.saveToken(token);
                  // store.commit('token', token);
                  // console.dir(resp);
                  /*switch (drive) {
                    case 'GOOGLEDRIVE':
                      service = 'google';
                      break;
                    case 'DROPBOX':
                      service = 'dropbox';
                      break;
                    case 'DROPBOXBUSINESS':
                      service = 'dropboxbusiness';
                      break;
                    case 'ONEDRIVE':
                      service = 'onedrive';
                      break;
                    case 'BOX':
                      service = 'box';
                      break;
                  }
                  userData = { 
                    accessToken: token,
                    redirect: '/'+service,
                    drive: drive,
                  };*
                  win.close()
                  clearInterval(int) 
                  store.commit('IS_LOADING', false);
                  console.dir({auth_token:token, provider_id:service});
                  //resolve({auth_token:token, provider_id:service});    
                  //const { access_token, provider_id } = resp;
                  //let resBody = await resp.json()
                  store.commit('IS_LOADING', false);
                  console.dir(resp);
                  console.log(token);
                  console.log(service)
                  console.log('---res body2---')          
                  if(addAccount) obj.addDrive({access_token: token, account_id: account_id, provider_id: service})
                  else {
                    let resBody = resp.json()
                    store.commit('IS_LOADING', false);
                    console.dir(resBody);
                    console.log('---res body3---')
                    return resBody;
                  }
                  /*alert('sso response')
                  let resBody = await res.json()
                  store.commit('IS_LOADING', false);
                  console.dir(resBody);
                  console.log('---res body3---')
                  return resBody;            */
                  //store.commit('IS_LOADING', true);
                  /*context.dispatch("storeUser", {drive: drive, userData: userData}).then(async () => {
                    /*await store.dispatch('loginUserdata', { drive: drive }).then(async () => {
                      window.location = '/'+drive.toLowerCase();
                    });*
                    //window.location = '/'+drive.toLowerCase();
                              
                  //});
                }*/
              } catch(e) {
                console.log('--err');
                console.log(e);
                console.dir(win);
                if(win.closed === true) {
                  clearInterval(int) 
                  store.commit('IS_LOADING', false);
                  //reject(e);
                }
              }
            }, 500)

            
            if(res) {
              console.log('---wincheck')
              clearInterval(int) 
              return res;
            }
          //});
      })
    }, 
  //}, 
}

export { Drivey }