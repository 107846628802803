import { createApp } from 'vue';
import App from './App';
import router from './router';
import store from "@/store";

import bootstrap from 'bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import jQuery from 'jquery';
//import config from './services/Google/config';
//import googleAuth from '@/services/Google/auth.js';
// import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
// import { library } from '@fortawesome/fontawesome-svg-core';
// import { fas } from '@fortawesome/free-solid-svg-icons';
// import { fab } from '@fortawesome/free-brands-svg-icons';
// import { far } from '@fortawesome/free-regular-svg-icons';

import VueToast from 'vue-toast-notification';
import 'vue-toast-notification/dist/theme-sugar.css';
import VueLoading from 'vue-loading-overlay';
import Vue3Lottie from 'vue3-lottie'
import 'vue-loading-overlay/dist/vue-loading.css';
import VueTippy from 'vue-tippy';
import 'tippy.js/dist/tippy.css'; // optional for styling
import SmartTable from 'vuejs-smart-table'
//import LoadScript from "vue-plugin-load-script";
import VueGtag from "vue-gtag";


// library.add(fas, fab, far);

const app = createApp(App, {});

app.use(VueToast);
app.use(VueLoading);
app.use(store);
app.use(bootstrap);
app.use(router);
app.use(VueTippy);
app.use(Vue3Lottie)
//app.use(LoadScript);
app.use(jQuery);
app.use(SmartTable)
// app.use(library);

//const prompt = 'select_account'
/*const GoogleAuthConfig = Object.assign(
  { scope: 'profile email' },
  {
    clientId: config.CLIENT_ID,
    scope: config.SCOPES,
  }
);*/

// Install Vue plugin
//app.config.globalProperties.$gapi = googleAuth;
//app.config.globalProperties.$gapi.load(GoogleAuthConfig, prompt)

app.use(VueGtag, {
  config: {id: 'G-8DQNWG3Z4Z'}
}).mount('#app');
