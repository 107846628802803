'use strict';

const generatedRoutes = [];

// add keywords here to generate new routes, make sure to add corresponding .vue file in '@/views' folder
const routeNames = ['About', 'OneDrive', 'GoogleDrive', 'Dropbox', 'DropboxBusiness', 'Aws', 'Ftp', 'Nas', 'Search', 'Drivey', 'Encrypted', 'Favorites', 'Backups', 'Settings', 'Shortcuts']; // else, manually add route in './routes.js' file

for (const routeName of routeNames) {
  const props = {
      endpoint: routeName.toLowerCase(),
      mod_base_url: '/' + routeName.toLowerCase(),
      driveName: routeName.toUpperCase(),
      page_title: routeName,
      lc_item: routeName,
      lc_item_plural: routeName,
      cc_item: routeName,
      cc_item_plural: routeName,
      base_breadcrumbs: [
          {
              link: '/',
              desc: routeName,
          }
      ]
  };

  generatedRoutes.push(
    {
        path: props.mod_base_url,
        component: () => import(`@/views/${routeName}.vue`),
        meta: props
    }
  );
}

export default generatedRoutes;
