import { createRouter, createWebHistory, createWebHashHistory } from 'vue-router';
import routes from './routes';
// import store from '../store'

const router = createRouter({
  mode: 'history',
  history: process.env.IS_ELECTRON ? createWebHashHistory() : createWebHistory(),
  routes
});

// router.beforeEach(() => {
//   console.log( 'store state', store.state.authModule);
//   return store.state.authModule.isLoggedIn;
// });

export default router;