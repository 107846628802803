//=========================================================================
// Validation
//=========================================================================
export const LOADING = "LOADING";
export const SET_ERROR = "setError";
export const RESET_STATE = "resetModuleState";

//=========================================================================
// Auth
//=========================================================================
export const LOGIN = "LOGIN";
export const AUTH_SUCCESS = "LOGIN_SUCCESS";
export const AUTH_ERROR = "LOGIN_ERROR";
export const LOGOUT = "LOGOUT";
export const AUTH_USER = "AUTH_USER";
export const AUTH_TOKEN = "AUTH_TOKEN";
export const AUTH_LOADING = "AUTH_LOADING";
export const USER_UPDATED = "USER_UPDATED";

//=========================================================================
// Pages
//=========================================================================
export const PAGE_LOADING = "PAGE_LOADING"
export const PAGE_SUCCESS = "PAGE_SUCCES"
export const PAGE_ERROR = "PAGE_ERROR"