//===============================================================================
// Imports
//===============================================================================
import {
  RESET_STATE
} from '../mutations.type'

//===============================================================================
// Initial State
//===============================================================================
const initialState = {
  showModal: false,
  modalType: '',
  item: null, 
  drive: '',
  type: '',
  data: {},
  step: 0
}

const state = { ...initialState }

const modalTypes = [
  'sign-in', 'upload', 'select-drive', 'create-folder', 'copy', 'move', 'share',
  'shortcut', 'backup', 'label', 'add-drive', 'billing', 'update-card', 'update-profile',
  'confirmation', 'remove'
];

//===============================================================================
// Actions
//===============================================================================
const actions = {
  /**
   * @description open/close modal, update UI, and setup for drive
   * @param {object} state
   * @param {object} payload
   * @param {'ONE_DRIVE' | 'GOOGLE_DRIVE' | 'DROPBOX' | 'ICLOUD' | 'DRIVEY' | 'AWS'} payload.drive
   * @param {boolean} payload.showModal
   * @param {'sign-in' | 'upload' | 'select-drive' | 'copy'} payload.modalType
    */
  updateModal({ state }, payload) {
    try {
      const { showModal, modalType, drive, step, type, data } = payload;

      if (typeof showModal !== 'boolean')
        throw Error('showModal is required as type boolean', showModal)

      if (showModal && !(new RegExp(modalTypes.join('|'), 'i').test(modalType)))
        throw Error(`modalType is must be one of the following keywords: '${modalTypes.join('\', \'')}'`, modalType);
      state.showModal = showModal;
      state.modalType = modalType;

      if (step !== undefined)
        state.step = step;

      if (drive && /onedrive|google|icloud|dropbox|dropboxbusiness|drivey|aws/i.test(drive))
        state.drive = drive;

      if (type !== undefined)
        state.type = type;

      if (data !== undefined)
        state.data = data;

    } catch (err) {
      console.error(`[updateModal]:`, err);
    }
  }
}

//===============================================================================
// Mutations
//===============================================================================
const mutations = {

  //-------------------------------------------------------------------
  // Reset State
  //-------------------------------------------------------------------
  [RESET_STATE]() {
    Object.keys(initialState).forEach(key => { state[key] = initialState[key] })
  }
}

//===============================================================================
// Getters
//===============================================================================
const getters = {
  modalState: state => () => state
}

export default {
  state,
  actions,
  mutations,
  getters
};
