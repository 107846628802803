import { createStore } from 'vuex';
//import googleModule from './modules/google.module';
import breadcrumbsModule from './modules/breadcrumbs.module';
import renameModule from './modules/rename.module';
import authModule from './modules/auth.module'
import actionsModule from './modules/actions.module'
import createPersistedState from "vuex-persistedstate";
import modalModule from './modules/modal.module';
import undoModule from './modules/undo.module';
import searchModule from './modules/search.module';
import toastModule from './modules/toast.module';
import billingModule from './modules/billing.module';

export default createStore({
  modules: {
    //googleModule,
    breadcrumbsModule,
    renameModule,
    authModule,
    actionsModule,
    modalModule,
    undoModule,
    searchModule,
    toastModule,
    billingModule
  },
  plugins: [createPersistedState({
    key: 'vuex',
    reducer (val) {
      if(val.authModule.isLoggedIn === false) { // return empty state when user logged out
        return {}
      }
      return val
    }
  })],
})
