// import drivey from '@/services/Drivey';

  const initialState = {
    toast: {
        message: '',
        position: '',
        type: '',
        duration: 5000
    },
    progressBar: {
      status: '',
      show: false,
    }
  }
  
  const state = { ...initialState }
  
  const actions = {
    async openProgress(context, payload = { status: '', show: false}) {
      const { status, show } = payload
      console.log('status', status)
      // await drivey.statusBackups();
      state.progressBar.show = show,
      setTimeout(() => {
        state.progressBar.show = show
      }, 5000);
    }
  }
  
  const mutations = {
    'SET_TOAST'(state, payload) {
        state.toast = payload;
    },
    'SET_PROGRESS'(state, payload) {
      state.progressBar = payload
    }
  }
  
  const getters = {
    toast: state => state.toast
  }
  
  export default {
    state,
    actions,
    mutations,
    getters
  };