<template>
  <router-view/>
</template>



<style lang="scss">
@import "./assets/scss/main.scss";

body, #app {
  margin: 0;
  font-family: 'Hind';
}
</style>
