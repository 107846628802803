import axios from 'axios';
import { TokenService } from '@/services/storage.service'
import store from "@/store";

const baseUrl = process.env.VUE_APP_DRIVEY_BASE_URL;
let accessToken = TokenService.getToken();

const settings = {
  headers: {
    'Content-Type': 'application/json',
    'Accept': 'application/json',
    'Authorization': `Bearer ${accessToken}`,
  },
  method: 'POST',
}

export default  {

  async billingDashboard() {
    // settings = {...settings, headers: {'Authorization': `Bearer ` + TokenService.getToken()}}
    store.commit('IS_LOADING', true);
    let response = await axios.get(`${baseUrl}/api/v1/billing/dashboard`, settings)
console.dir(response);
console.log('--------billing')
    if(response.status !== 200) {
      store.commit('IS_LOADING', false);
      if(response.status === 401) throw Error('401 Unauthenticated');
      else throw Error('There was an error')
    }
    store.commit('IS_LOADING', false);

    return response
  },

  async getTiers() {
    store.commit('IS_LOADING', true);
    let response = await axios.get(`${baseUrl}/api/v1/billing/tiers`, settings)

    if(response.status !== 200) {
      store.commit('IS_LOADING', false);
      throw Error('There was an error')
    }
    store.commit('IS_LOADING', false);
    return response
  },
  async membershipChange(tier_id) {
    store.commit('IS_LOADING', true);
    let response = await axios.post(`${baseUrl}/api/v1/billing/memberships/change?tier_id=${tier_id}`, '', settings)

    if(response.status !== 200) {
      store.commit('IS_LOADING', false);
      throw Error('There was an error')
    }
    store.commit('IS_LOADING', false);
    return response
  },
  async membershipSuspend() {
    store.commit('IS_LOADING', true);
    let response = await axios.post(`${baseUrl}/api/v1/billing/memberships/suspend`, '', settings)

    if(response.status !== 200) {
      store.commit('IS_LOADING', false);
      throw Error('There was an error')
    }
    store.commit('IS_LOADING', false);
    return response
  },
  async membershipCancel() {
    store.commit('IS_LOADING', true);
    let response = await axios.post(`${baseUrl}/api/v1/billing/memberships/cancel`, '', settings)

    if(response.status !== 200) {
      store.commit('IS_LOADING', false);
      throw Error('There was an error')
    }
    store.commit('IS_LOADING', false);
    return response
  },
  async membershipActive() {
    store.commit('IS_LOADING', true);
    let response = await axios.get(`${baseUrl}/api/v1/billing/memberships/Active`, '', settings)

    if(response.status !== 200) {
      store.commit('IS_LOADING', false);
      throw Error('There was an error')
    }
    store.commit('IS_LOADING', false);
    return response
  },
  async membershipAll() {
    store.commit('IS_LOADING', true);
    let response = await axios.get(`${baseUrl}/api/v1/billing/memberships/all`, '', settings)

    if(response.status !== 200) {
      store.commit('IS_LOADING', false);
      throw Error('There was an error')
    }
    store.commit('IS_LOADING', false);
    return response
  },
  async membershipOptionsAll() {
    store.commit('IS_LOADING', true);
    let response = await axios.get(`${baseUrl}/api/v1/billing/memberships/options/all`, settings)

    if(response.status !== 200) {
      store.commit('IS_LOADING', false);
      throw Error('There was an error')
    }
    store.commit('IS_LOADING', false);
    return response
  },
  async membershipOptionsAdd(id, qty) {
    store.commit('IS_LOADING', true);
    let response = await axios.post(`${baseUrl}/api/v1/billing/memberships/options/add?option_id=${id}&qty=${qty}`, '', settings)

    if(response.status !== 200) {
      store.commit('IS_LOADING', false);
      throw Error('There was an error')
    }
    store.commit('IS_LOADING', false);
    return response
  },
  async membershipOptionsUpdate(id, qty) {
    store.commit('IS_LOADING', true);
    let response = await axios.post(`${baseUrl}/api/v1/billing/memberships/options/update?option_id=${id}&qty=${qty}`, '', settings)

    if(response.status !== 200) {
      store.commit('IS_LOADING', false);
      throw Error('There was an error')
    }
    store.commit('IS_LOADING', false);
    return response
  },
  async cardsCreate(token) {
    store.commit('IS_LOADING', true);
    let response = await axios.post(`${baseUrl}/api/v1/billing/cards/create?token=${token}`, '', settings)

    if(response.status !== 200) {
      store.commit('IS_LOADING', false);
      throw Error('There was an error')
    }
    store.commit('IS_LOADING', false);
    return response
  },

  async cardsRemove(card_id) {
    store.commit('IS_LOADING', true);
    let response = await axios.post(`${baseUrl}/api/v1/billing/cards/remove?card_id=${card_id}`, '', settings)

    if(response.status !== 200) {
      store.commit('IS_LOADING', false);
      throw Error('There was an error')
    }
    store.commit('IS_LOADING', false);
    return response
  },

  async cardsReplace(card_id) {
    store.commit('IS_LOADING', true);
    let response = await axios.post(`${baseUrl}/api/v1/billing/cards/replace?card_id=${card_id}`, '', settings)

    if(response.status !== 200) {
      store.commit('IS_LOADING', false);
      throw Error('There was an error')
    }
    store.commit('IS_LOADING', false);
    return response
  },

  async cardsUser() {
    store.commit('IS_LOADING', true);
    let response = await axios.get(`${baseUrl}/api/v1/billing/cards/user`, settings)

    if(response.status !== 200) {
      store.commit('IS_LOADING', false);
      throw Error('There was an error')
    }
    store.commit('IS_LOADING', false);
    return response
  },

  async invoicesAll() {
    store.commit('IS_LOADING', true);
    let response = await axios.get(`${baseUrl}/api/v1/billing/invoices/all`, settings)

    if(response.status !== 200) {
      store.commit('IS_LOADING', false);
      throw Error('There was an error')
    }
    store.commit('IS_LOADING', false);
    return response
  },

  async invoicesUser() {
    store.commit('IS_LOADING', true);
    let response = await axios.get(`${baseUrl}/api/v1/billing/invoices/user`, settings)
    if(response.status !== 200) {
      store.commit('IS_LOADING', false);
      throw Error('There was an error')
    }
    store.commit('IS_LOADING', false);
    return response
  }

}
