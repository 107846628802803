//===============================================================================
// Imports
//===============================================================================
import {
  RESET_STATE
} from '../mutations.type'

//===============================================================================
// Initial State
//===============================================================================
const initialState = {
  id: '',
  currentName: '',
  newName: '',
}

const state = initialState;

//===============================================================================
// Actions
//===============================================================================
const actions = {
  /**
   * @description set rename state to begin renaming drive item
   * @param {object} state
   * @param {object} payload
   * @param {string} payload.id
   * @param {string} payload.currentName
   */
  initRename({ state }, payload = { id: '', currentName: '' }) {
    const { id, currentName } = payload;

    if (!id)
      console.error(`[initRename]: id is required for payload`, id);

    if (!currentName)
      console.error(`[initRename]: currentName is required for payload`, currentName);

    state.id = id;
    state.currentName = currentName;
    state.newName = currentName;
  },
  /**
   * @description event handler to watch for user's key entries and disallow characters that do not meet drive requirements
   * @param {object} state
   * @param {event} event
   * @param {object} event.target
   *
   * Note: regex is current based on OneDrive requirements
   * - if another drive has different requirements, pass event & this.drive in payload and set up conitional
   */
  monitorRename({ state }, event) {
    if (!event || typeof event !== 'object' || !event.target)
      console.error(`[monitorRename]: event requirements not met for handler`, event);

    const key = String.fromCharCode(!event.charCode ? event.which : event.charCode);
    const allowed = new RegExp("^[a-zA-Z0-9| |-|_]+$");

    if (!allowed.test(key)) {
      event.preventDefault();
      return false;
    }

    state.newName = event.target.value + key;
  },
  /**
   * @description event handler to return to initialState
   * @param {object} state
   */
  endRename({ state }) {
    Object.assign(state, initialState);
  },
  resetRenameState({ commit }) {
    commit(RESET_STATE);
  }
}

//===============================================================================
// Mutations
//===============================================================================
const mutations = {

  //-------------------------------------------------------------------
  // Reset State
  //-------------------------------------------------------------------
  [RESET_STATE]() {
    Object.keys(initialState).forEach(key => { state[key] = initialState[key] })
  }
}

//===============================================================================
// Getters
//===============================================================================
const getters = {
  // lastBreadcrumb: state => drive => {
  //   const breadcrumbs = state.breadcrumbs[drive];
  //   return breadcrumbs[breadcrumbs.length - 1];
  // },
  // allBreadcrumbs: state => drive => {
  //   return state.breadcrumbs[drive];
  // }
  isRenaming: state => id => id === state.id,
  newName: state => state.newName
}

export default {
  state,
  actions,
  mutations,
  getters
};
