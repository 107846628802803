//===============================================================================
// Imports
//===============================================================================
// import googleAuth from '../../services/Google/auth'
import {
  RESET_STATE
} from '../mutations.type'

//===============================================================================
// Initial State
//===============================================================================
const initialState = {
  breadcrumbs: {
    onedrive: {}, //{ name: 'All Files', path: '', prefix: '', shared_drive_id: '' }
    google: {}, //{ name: 'All Files', path: '', prefix: '', shared_drive_id: '' }
    dropbox: {}, //{ name: 'All Files', path: '', prefix: '' }
    dropboxbusiness: {}, //{ name: 'All Files', path: '', prefix: '' }
    icloud: {}, //{ name: 'All Files', path: '', prefix: '' }
    aws: {}, //{ name: 'All Files', path: '', prefix: '' }
    drivey: {}, //{ name: 'All Files', path: '', prefix: '' }
    ftp: {}, //{ name: 'All Files', path: '', prefix: '' }
    nas: {}, //{ name: 'All Files', path: '', prefix: '' }
  },
  activeCrumbs: {}, 
  lastCrumb: {},
  currPath: '', 
  //root: false
}

const state = { ...initialState }

//===============================================================================
// Actions
//===============================================================================
const actions = {

  //-------------------------------------------------------------------
  // updateBreadcrumbs
  //-------------------------------------------------------------------
  /**
   * @description if breadcrumb is not yet in array then add, else make breadcrumb as last elem in array (discards elems after)
   * @param {object} state
   * @param {object} payload
   * @param {'ONEDRIVE' | 'GOOGLE' | 'DROPBOX' | 'DROPBOXBUSINESS' | 'ICLOUD' | 'AWS'} payload.drive
   * @param {object} payload.item drive item data
   * @param {string} payload.item.name will be title of breadcrumb
   * @param {string} payload.item.title will be title of breadcrumb
   * @param {string} payload.item.id path used to make api call
    */
  updateBreadcrumbs(context, payload) {
    const { service, id, item } = payload;
    let breadcrumb = null;
    let allBreadcrumbs = state.breadcrumbs

    if(!service || !id) return;

    switch (service) {
      // case 'ONEDRIVE':
      //   breadcrumb = { name: item.name, prefix: 'me/drive/items/', path: item.id + '/children' };
      //   break;
      case 'onedrive': 
      case 'google':
      case 'aws':
      case 'drivey':
      case 'ftp':
      case 'nas':
        breadcrumb = { name: item.name, prefix: '', path: item.path, shared_drive_id: item.shared_drive_id }
        break;
      case 'dropbox':
        breadcrumb = { name: item.name, path: item.path };
        break;
      case 'dropboxbusiness':
        breadcrumb = { name: item.name, path: item.path };
        break;
      default: {
        console.log(`[updateBreadcrumbs]: invalid drive string`, service);
      }
    }
console.dir(state.breadcrumbs)
    if(!state.breadcrumbs[service][id]) {
      console.log(id)
      console.log('state breadcrumbs')
      allBreadcrumbs[service][id] = new Array(breadcrumb);
    } else {
      allBreadcrumbs[service][id] = context.state.breadcrumbs[service][id].slice(0, context.state.breadcrumbs[service][id].findIndex(b => b.path === breadcrumb.path) + 1);

      console.dir(state.breadcrumbs[service][id])
    }
    context.commit('SET_BREADCRUMBS', allBreadcrumbs);
    context.dispatch('setBreadcrumbs', {service:service, id:id});
    console.dir(context.state.breadcrumbs)
  },
  initBreadcrumbs(context, payload) {
    const {service, id} = payload

    context.dispatch('updateBreadcrumbs', { service: service, id: id, item: {name: "All Files", prefix:'', path:''} });

  }, 
  /*setLastCrumb(context, payload) {
    const { service, id } = payload
     
    if(service && id) {
      const breadcrumbs = state.breadcrumbs[service][id];
      console.dir(breadcrumbs)
      console.log('----last bread')
      if (breadcrumbs) {
        context.commit('SET_LASTCRUMB', breadcrumbs[breadcrumbs.length - 1]);
      }
    }
  }, */
  getBreadcrumbs(context, payload) {
    const {service, id} = payload
    /*console.log('---all breadcrumbs')
    console.dir(state.breadcrumbs)
    let crumb = service && id ? state.breadcrumbs[service][id] : null

    if(crumb == undefined && service && id) {
      context.dispatch('updateBreadcrumbs', { service: service, id: id, item: {name: "All Files", prefix:'', path:''} });
      crumb = [{name: "All Files", prefix:'', path:''}];
    }
   console.dir(crumb);
    if(window.location.search === '?shared=1') {
      const index = crumb.findIndex(item => item.name === 'All Files');
      if(index !== -1)
      crumb[index] = {...crumb[index], name: "Shared"};
    }
    console.log('crumb', crumb)
    return crumb;*/
    return context.state.breadcrumbs[service][id]
  }, 
  setBreadcrumbs(context, payload) {
    const {service, id} = payload
    /*console.log('---all breadcrumbs')
    console.dir(state.breadcrumbs)
    let crumb = service && id ? state.breadcrumbs[service][id] : null

    if(crumb == undefined && service && id) {
      context.dispatch('updateBreadcrumbs', { service: service, id: id, item: {name: "All Files", prefix:'', path:''} });
      crumb = [{name: "All Files", prefix:'', path:''}];
    }
   console.dir(crumb);
    if(window.location.search === '?shared=1') {
      const index = crumb.findIndex(item => item.name === 'All Files');
      if(index !== -1)
      crumb[index] = {...crumb[index], name: "Shared"};
    }
    console.log('crumb', crumb)
    return crumb;*/
    context.commit('SET_ACTIVECRUMBS', context.state.breadcrumbs[service][id])
    context.commit('SET_LASTCRUMB', context.state.breadcrumbs[service][id][context.state.breadcrumbs[service][id].length - 1])
    context.commit('SET_CURRPATH', context.state.breadcrumbs[service][id][context.state.breadcrumbs[service][id].length - 1].path)
    return context.activeCrumbs
  }, 
  // TODO WIP
  resetBreadcrumbs(context) {
    console.log(`[resetBreadcrumbs]`);
    // context.commit(RESET_STATE);
    console.log('reset;bread', context.state);
  },
  /**
   * @description Go back to previous breadcrumb through Drive Navigation component
   * @param {object} state
   * @param {object} payload
   * @param {string} payload.service
   * @param {string} payload.path
   */
  returnToBreadcrumb( { state }, payload) {
    const { path, service } = payload;
    console.dir(state.breadcrumbs[service])
    if (state.breadcrumbs[service][id].find(b => b.path === path))
      state.breadcrumbs[service][id] = state.breadcrumbs[service][id].slice(0, state.breadcrumbs[service][id].findIndex(b => b.path === path) + 1);
    console.dir(state.breadcrumbs[service])
  }, 
  /*rootDrive(context, payload) {
    context.commit('SET_ROOT', payload)
  }*/
}

//===============================================================================
// Mutations
//===============================================================================
const mutations = {
  'SET_ROOT'(state, root) {
    console.log('SET_ROOT', root)
    state.root = root
  },
  'SET_BREADCRUMBS'(state, breadcrumbs) {
    //alert('====set====')
    console.log('--set breadcrumbs')
    console.dir(breadcrumbs)
    state.breadcrumbs = { ...state.breadcrumbs, ...breadcrumbs }
  },
  'SET_ACTIVECRUMBS'(state, breadcrumbs) {
    state.activeCrumbs = breadcrumbs
  }, 
  'SET_LASTCRUMB'(state, breadcrumb) {
    state.lastCrumb = breadcrumb
  }, 
  'SET_CURRPATH'(state, path) {
    state.currPath = path
  }, 
  //-------------------------------------------------------------------
  // Reset State
  //-------------------------------------------------------------------
  [RESET_STATE]() {
    Object.keys(initialState).forEach(key => { state[key] = initialState[key] })
  }
}

//===============================================================================
// Getters
//===============================================================================
const getters = {
  activeCrumbs: state => state.activeCrumbs, 
  allBreadcrumbs: state => state.breadcrumbs,
  currPath: state => state.currPath, 
  lastCrumb: state => state.lastCrumb,   
  /*rootBreadcrumbs: state => {
    return Object.keys(state.breadcrumbs)
  },
  getRoot: state => state.root*/
}

export default {
  state,
  actions,
  mutations,
  getters
};
