//===============================================================================
// Imports
//===============================================================================
//-------------------------------------------------------------------------------
// One Drive
//-------------------------------------------------------------------------------
//import msGraph from '@/services/OneDrive/graph';
//import dropboxbusiness from '@/services/DropboxBusiness';
//import onedrive from '@/services/OneDrive';
import { Drivey } from '@/services/Drivey';
//import {useLoading} from 'vue-loading-overlay'
//const $loading = useLoading({});
//-------------------------------------------------------------------------------
// Google Drive
//-------------------------------------------------------------------------------
//import googleAuth from '@/services/Google/auth';
import { RESET_STATE } from '../mutations.type';
import store from "@/store";

//===============================================================================
// Initial State
//===============================================================================
const initialState = {
  // drives: {
  //   drivey: [],
  // },
  featuresList: {
    encrypted: [],
    shortcuts: [],
    favorites: [],
    backups_0: [],
    backups_1: [],
  },
  modalList: {}, 
  list: {
    drivey: {}, 
    aws: {},
    google: {},
    onedrive: {},
    dropbox: {},
    ftp: {},
  }, 
  itemToCopy: {},
  // toastMessage: '',
  itemToMove: {},
  searchList: {
    onedrive: [],
    google: [],
    dropbox: [],
    dropboxbusiness: [],
    icloud: [],
    aws: [],
    ftp: [],
    drivey: []
  },
  backups: [],
  //listdrives: [],
  root: {},
  clearSelected: false, 
  sidebarVisible: false,
  isLoading: false, 
  listLoading: false, 
  modalListLoading: false, 
  featureListLoading: false, 
  lastSort: 'date', 
  menuActive: false, 
}

const state = () => {
  return initialState
}

//===============================================================================
// Actions
//===============================================================================
const actions = {
  //-------------------------------------------------------------------
  // Move Item
  //-------------------------------------------------------------------
  async moveItem(_context, payload = { asset_id: null, drive_id: '', dest_drive: null, dest_id: null }) {
    try {
      const { asset_id, drive_id, dest_drive, dest_id } = payload;
      let res;

      if (!asset_id || !drive_id || !dest_drive) {
        throw Error (`item, destination, & drive are required`, { asset_id, dest_id, drive_id });
        /*case 'AWS': {
          res = await Drivey.moveItem({service: 's3', dest_service: destinationRoot, dest_location: destination.id, id:item.id, newName: item.name});
          break;
        }*/
      } else {
        res = await Drivey.moveItem({ asset_id: asset_id, drive_id: drive_id, dest_drive_id: dest_drive.id, dest_id: dest_id})
      }
      if(res && res.code == 'Set Error') {
        _context.commit('SET_TOAST', { message: res.message, position: 'top-right', type: 'error', duration: 5000 });
        throw Error(res)
      } else {
        _context.dispatch('getDrive', { path: dest_id, service: dest_drive.service, drive_id: dest_drive.id });
        _context.commit('SET_TOAST', { message: 'Your folder was moved successfully.', position: 'top-right', type: 'success', duration: 5000 });
        _context.dispatch('getTracking', {});
      }
    } catch (err) {
      _context.commit('SET_TOAST', { message: err.message, position: 'top-right', type: 'error', duration: 5000 });
      console.error('[moveItem]: failed', err);
    }
  },

   //-------------------------------------------------------------------
  // Copy Item To
  //-------------------------------------------------------------------
  async copyItemTo(_context, payload = { asset_id: null, drive_id: '', dest_id: null, dest_drive: null }) {
    const { asset_id, drive_id, dest_drive, dest_id } = payload;
    //let breadcrumb = _context.rootGetters.lastBreadcrumb(drive_id)

    if (!asset_id || !drive_id || !dest_drive) {
      throw Error (`item, destination, & drive are required`, { asset_id, dest_drive, drive_id });

    }
    await Drivey.copyItem({ drive_id: drive_id, dest_drive_id: dest_drive.id, asset_id:asset_id, dest_id: dest_id }).then((res) => {
      if(res && res.code == 'Set Error') {
        _context.commit('SET_TOAST', { message: res.message, position: 'top-right', type: 'error', duration: 5000 });
        throw Error(res)
      } else {
        _context.commit('SET_TOAST', { message: 'Item successfully copied.', position: 'top-right', type: 'success', duration: 5000 })
        //_context.dispatch('getDrive', { path: dest_drive.id, drive_id: dest_drive.id, service: dest_drive.service/*, prefix: breadcrumb.prefix*/ });
        _context.dispatch('getTracking', {});
      }
    }).catch((err) => {
      _context.commit('SET_TOAST', { message: err.message, position: 'top-right', type: 'error', duration: 5000 });
      console.error('[copyItem]: failed', err);
    });
  },



  //-------------------------------------------------------------------
  // Rename Item
  //-------------------------------------------------------------------
  async renameItem(context, payload = { asset_id: null, service: '', name: '' }) {
    let res;
    try {
      const { asset_id, service, name } = payload;
      console.log('payload rename', payload)
      if (!asset_id || !service || !name)
        throw Error (`item & service are required\nitem: ${asset_id}\nservice: ${service}`);

      res = Drivey.renameItem({asset_id: asset_id, name: name, service: service})

      
      if(res && res.code == 'Set Error') {
        context.commit('SET_TOAST', { message: res.message, position: 'top-right', type: 'error', duration: 5000 });
        throw Error(res)
      } else {
          context.commit('RENAME_LIST_ITEM', { asset_id: asset_id, name: name, service:service })
          context.commit('SET_TOAST', { message: 'Item was renamed successfully.', position: 'bottom-right', type: 'success', duration: 5000 })
      }
    } catch (err) {
      console.error('[renameItem]: failed', err);
    }
  },

  //-------------------------------------------------------------------
  // Update Drive
  //-------------------------------------------------------------------
  /*async updateDrive(_context, payload = { item: null, drive: '', options: null }) {
    try {
      const { item, drive, options } = payload;
      console.log('payload updatedriver', options)

      if (!item || !options)
        throw Error (`item, options are required\nitem: ${item}\noptions: ${options}\ndrive: ${drive}`);

      switch (drive) {
        case 'ONEDRIVE': {
          await msGraph.drive(item.id, 'PATCH', options, 'me/drive/items/');
          break;
        } default: {
          throw Error(`invalid drive name, ${drive}`);
        }
      }
    } catch (err) {
      console.error('[updateDrive]: failed', err);
    }
  },

  //-------------------------------------------------------------------
  // Get Root
  //-------------------------------------------------------------------
  async getRoot(context, payload) {
    // const {drive} = payload;
    // console.log('root drive', drive)
    console.log("root hello?", payload)
    // switch(drive) {
    //   case 'ONEDRIVE': {

    //     let root = await onedrive.getRoot();
        context.commit('SET_ROOT')
    //     break;
    //   }
    // }
  },*/

  //-------------------------------------------------------------------
  // Get User Info
  //-------------------------------------------------------------------
  async getUserInfo(context) {
    return new Promise((resolve, reject) => {
      Drivey.getUserInfo().then(res => {
        if(res && res.code == 'Set Error') {
          context.commit('SET_TOAST', { message: res.message, position: 'top-right', type: 'error', duration: 5000 });
          throw Error(res)
        } else {
          resolve(res)
        }
      }).catch(err => {
        reject(err)
      })
    })
  },

  //-------------------------------------------------------------------
  // Get Drive
  //-------------------------------------------------------------------
  async listEncrypted(context) {
    context.commit('SET_FEATURES',[{  }, true]);
    let res = await Drivey.listEncrypted();
    if(res && res.code == 'Set Error') {
      context.commit('SET_TOAST', { message: res.message, position: 'top-right', type: 'error', duration: 5000 });
      throw Error(res)
    } else {
      context.commit('SET_FEATURES',[{ ['encrypt'] : res.encrypt }, false]);
    }
  }, 
  async listFavorites(context) {
    context.commit('SET_FEATURES',[{  }, true]);
    let res = await Drivey.listFavorites();
    console.dir(res);
    console.log('-----res')
    if(res && res.code == 'Set Error') {
      context.commit('SET_TOAST', { message: res.message, position: 'top-right', type: 'error', duration: 5000 });
      throw Error(res)
    } else {
      if(!res.length) context.commit('SET_FEATURES',[{ ['favorites'] : [] }, false]);
      else context.commit('SET_FEATURES',[{ ['favorites'] : res }, false]);
    }
  }, 
  async listShortcuts(context) {
    context.commit('SET_FEATURES',[{  }, true]);
    let res = await Drivey.listShortcuts();
    if(res && res.code == 'Set Error') {
      context.commit('SET_TOAST', { message: res.message, position: 'top-right', type: 'error', duration: 5000 });
      throw Error(res)
    } else {
      context.commit('SET_FEATURES',[{ ['shortcuts'] : res.shortcuts }, false]);
    }
  },
  async listBackups(context, payload = { type: '' }) {
    const { type } = payload;
    console.dir(payload)
    console.log('-----lkist backups')
    context.commit('SET_FEATURES',[{  }, true]);
    let res = await Drivey.listBackups(payload);
    if(res && res.code == 'Set Error') {
      context.commit('SET_TOAST', { message: res.message, position: 'top-right', type: 'error', duration: 5000 });
      throw Error(res)
    } else {
      console.dir('type--', type)
      console.dir(res)
      context.commit('SET_FEATURES',[{ ['backups_'+type] : res.backups }, false]);
    }
  }, 

  async addItem(context, payload={item:'', service:'', path:'', driveId:''}) {
    const { item, service, driveId, path } = payload
    console.dir(item)
    //console.dir(drives[service].drives.filter(drive => drive.drive_id == drive_id)[0])

    context.commit('CREATE_LIST_ITEM', { service:service, driveId:driveId, path:path, item:item });
  }, 

  async getDrive(context, payload = { path: '', service:'', drive_id: '', filters: {}, modal: false,/* shared_drive_id: '', shared: null,*/ retry: 0}) {
    const { path, filters, service, drive_id, modal, retry/*, shared_drive_id, shared*/ } = payload;
    console.log('the pdrive', payload)
    let setList = false, newTry = payload.retry;
    let id = (drive_id) ? drive_id : (this.drives) ? this.drives[this.service].drives.filter(drive => drive.default_drive == 1)[0].id : 0;
    let dir = path ? path : '';
    try {
      /*if(drive && !modal){
        context.commit('SET_LIST', [{ [drive] : [] }, true]);
      } else if(drive && modal) {
        context.commit('SET_MODAL_LIST', [{ [drive] : [] }, true]);
      }*/

      /*let loader = $loading.show({
        height: 80,
        width: 80,
        loader: 'spinner',
        color: 'purple',
        opacity: 0.0,
      });*/
      context.commit('IS_LOADING', true);
      console.log(service);
      console.log(drive_id);
      console.log('-------getdrive actions')
      if(!service || !drive_id) return;
      await Drivey.fileList({ path: dir, service:service, drive_id: id }).then((res) => {
        const { path, service, drive_id } = payload;
        let setList = true;
        let id = drive_id

        //if(setList && (!modal || modal == undefined)) context.commit('SET_LIST', [{ [service] : { [id] : { [path] : [] } } }, true]);
        //else if(setList) context.commit('SET_MODAL_LIST', [{  }, true]);
        
        if(res && res.code == 'Set Error') {
          context.commit('SET_TOAST', { message: res.message, position: 'top-right', type: 'error', duration: 5000 });
          throw Error(res.message)
        } else if(res != undefined) {
          console.dir(res);
          //let id = (drive_id) ? drive_id : 0;
          console.log(id);
          console.dir(path);
          console.dir(res)
          if(!id) id = this.drives[this.service].drives.filter(drive => drive.default_drive == 1)[0].id;
          console.log(id)
          console.log(modal)
          console.log('---adding getDrive')
          if(setList && (!modal || modal === undefined) && service) context.commit('SET_LIST', [service, id, path, res, false] );
          else if(setList && (modal || modal != undefined)) context.commit('SET_MODAL_LIST', [{ [service] : res }, false]);
        }
      })
      
          /*if(!payload.retry){
            payload.retry = 1
            context.dispatch('getDrive', payload);
          } else if(payload.retry <= 4){
            payload.retry = payload.retry+1;
            context.dispatch('getDrive', payload);
          } else {
            throw Error(`invalid drive name, ${drive.drive}`);
          }*/
      
       

      //loader.hide();
      context.commit('IS_LOADING', false);
    } catch (err) {
      /*let loader = $loading.show({
        height: 80,
        width: 80,
        loader: 'spinner',
        color: 'purple',
        opacity: 0.0,
      });*/
      context.commit('IS_LOADING', true);
      if(!newTry){
        newTry = 1
        context.dispatch('getDrive', { path: path, drive_id: drive_id, filters: filters, retry: newTry });
      } else if(newTry <= 4){
        newTry = retry+1;
        context.dispatch('getDrive', { path: path, drive_id: drive_id, filters: filters, retry: newTry });
      } else {
        console.log(`[getDrive]: `, err);
        //reject(err);
      }
      context.commit('IS_LOADING', false);
    }
  },

  //-------------------------------------------------------------------
  // List Drives
  //-------------------------------------------------------------------
  async getDrives(context) {
    console.dir(context);
    await Drivey.getDrives().then(res => {
      console.dir(res);
      console.log('---getting drives')
      context.commit('GET_DRIVES', res)
      //context.dispatch('drives', res);
    }).catch(err => {
      console.dir(err)
      alert(err);
      if(err.message.indexOf('401') > -1) {
        console.log('getdrives auth err')
        //TokenService.removeToken();
        context.dispatch('SET_LOGIN_STATUS', false);
        //this.$router.push(`/`);
        //this.logout();
      }
      //reject(err);
    })
  },

  //-------------------------------------------------------------------
  // Add Drives
  //-------------------------------------------------------------------
  async addDrive(context, payload = { drive: '', /*providerId: '', accountId: '', token: ''*/ }) {
    let { drive } = payload;
    let account_id = localStorage.getItem('userID');
    return new Promise((resolve, reject) => {
      Drivey.addDrive({ drive: drive, account_id: account_id }).then(res => {
        context.dispatch('getTracking', {});
        resolve(res.data)
      }).catch(err => {
        reject(err)
      })
    })
    /*await Drivey.addDrive('s3', this.access_key_id, this.secret_access_key).then(async () => {
      await store.dispatch('loginUserdata', { drive: 'S3' }).then(async () => {
        window.location = '/aws';
      });
    });*/
  },

  //-------------------------------------------------------------------
  // Delete Drives
  //-------------------------------------------------------------------
  async removeDrive(context, payload = { id: '' }) {
    let { id } = payload;
    context.commit('SETTINGS_DRIVE_MODAL_CLOSE', false);

    return new Promise((resolve, reject) => {
      Drivey.removeDrive(id).then(res => {
        context.commit('SETTINGS_DRIVE_MODAL_CLOSE', true);
        context.dispatch('getTracking', {});
        resolve(res.data)
      }).catch(err => {
        reject(err)
      })
    })
  },

  //-------------------------------------------------------------------
  // Delete Drives
  //-------------------------------------------------------------------
  async updateProfile(context, payload = { name: '', email: '', password: '' }) {
    let { name, email, password } = payload;
    context.commit('SETTINGS_DRIVE_MODAL_CLOSE', false);

    return new Promise((resolve, reject) => {
      Drivey.updateProfile(name, email, password).then(res => {
        context.commit('SETTINGS_DRIVE_MODAL_CLOSE', true);
        resolve(res.data)
      }).catch(err => {
        reject(err)
      })
    })
  },

  //-------------------------------------------------------------------
  // Change Default Drive
  //-------------------------------------------------------------------
  async changeDefaultDrive(context, payload = { drive_id: '', service: '', path: '' }) {
    try {
      const { drive_id, service, path } = payload
      // let drive = ''
      // switch (provider) {
      //   case 'google':
      //     drive = 'GOOGLEDRIVE'
      //     break;
      //   case 'onedrive':
      //     drive = 'ONEDRIVE'
      //     break;
      //   case 'drivey' :
      //     drive = 'DRIVEY'
      //     break
      //   case 's3' :
      //     drive = 'AWS'
      //     break;
      //   default:
      //     throw Error(`invalid provider, ${provider}`);
      // }
      console.log(drive_id)
      await Drivey.changeActive(drive_id).then(() => {
        context.dispatch('getDrive', { path: path, drive_id: drive_id, service: service, prefix: '' });
        context.dispatch('getDrives')
        context.commit('SET_ACTIVECRUMBS', context.rootState.allBreadcrumbs[service][id])
        context.commit('SET_LASTCRUMB', context.rootState.allBreadcrumbs[service][id][context.rootState.allBreadcrumbs[service][id].length - 1])
        context.commit('SET_CURRPATH', context.rootState.allBreadcrumbs[service][id][context.rootState.allBreadcrumbs[service][id].length - 1].path)    
        console.dir(context.rootState.currPatah)
        console.dir(context.rootState.activeCrumbs)
        console.log('----crumbs')
      });
    } catch (err) {
      console.log(`[changeDefaultDrive]: `, err);
    }
  },

  //-------------------------------------------------------------------
  // Get Tracking
  //-------------------------------------------------------------------
  async getTracking(context) {
    console.log(context)
    /*await Drivey.getTracking().then(res => {
      context.commit('SET_BANDWIDTH_STORAGE', res)
    })*/
  },

  //-------------------------------------------------------------------
  // Get Features
  //-------------------------------------------------------------------
  async getFeatures(context, payload = { path: '', prefix: '', drive: ''}) {
    try {
      const { path, prefix, drive } = payload;
      console.log(path, prefix, drive)
console.log('-----get features')
      switch (drive) {
        case 'shortcuts': {

          break;
        }
        case 'favorites' : {

          break;
        }
        case 'encrypted':
          break;

        case 'backups': {

          break;
        }
        default:
          throw Error(`invalid drive name, ${drive.drive}`);
      }
    } catch (err) {
      console.log(`[getFeatureS]: `, err);
    }
  },

  async shareItem(context, payload = { item: null, permission: '', drive: '' }) {
    //const { item, permission, drive } = payload;
    var link = payload;
    /*try {
      const { item, permission, drive } = payload;
      var link = '';
      store.commit('IS_LOADING', true);
      switch (drive) {
        case 'ONEDRIVE': {
          var response = await onedrive.createLink(item, permission);
          link = response.link.webUrl;
          console.log("link", link);
          break;
        }
        // case 'GOOGLEDRIVE' : {

        // }
        // case 'DROPBOX': {

        // }
        default: {
          store.commit('IS_LOADING', false);
          throw Error(`invalid drive name, ${drive.drive}`);
        }
      }
    } catch (err) {
      store.commit('IS_LOADING', false);
      console.log(`[shareItem]: `, err);
    }*/
    store.commit('IS_LOADING', false);
    return link;
  },

  //-------------------------------------------------------------------
  // Search Drives
  //-------------------------------------------------------------------
  async searchDrives(context, payload = { value: '', params: null }) {
    const { value, params } = payload;

    if(value !== ''){
      store.commit('IS_LOADING', true);
      await Drivey.search(value, params).then(res => {
        console.dir(res);
        if(res && res.code == 'Set Error') {
          context.commit('SET_TOAST', { message: res.message, position: 'top-right', type: 'error', duration: 5000 });
          alert('reject')
        } else if(res) {
          console.log('search vals', res)
          store.commit('IS_LOADING', false);
          context.commit('SET_SEARCH_LIST', res);
        }
      }).catch(err => {
        store.commit('IS_LOADING', false);
        context.commit('SET_TOAST', { message: err, position: 'bottom-right', type: 'success', duration: 5000 })
      });      
    }
    
  },

  //-------------------------------------------------------------------
  // Create Folder
  //-------------------------------------------------------------------
  async createFolder(context, payload = { name: null, service: '', path: '' }) {
    const { name, service, path } = payload;
    const item = { name: name }
    
    await Drivey.createFolder({ service: service, name: item.name, path: path }).then((metadata) => {
      //context.dispatch('getDrive', { path: path, service: payload.service });
      //const commitCreateFolder = context.commit('CREATE_LIST_ITEM', { item: item, service: service });
    
      context.commit('SET_TOAST', { message: 'New folder was created successfully.', position: 'bottom-right', type: 'success', duration: 5000 })
      
      //context.commit('CREATE_LIST_ITEM', { item: item, service: payload.service });
      console.dir(metadata);
      console.log('---metadata')
      commitCreateFolder(metadata);
      context.dispatch('getTracking', {});
    }).catch((err) => {
      context.commit('SET_TOAST', { message: err.message, position: 'top-right', type: 'error', duration: 5000 });
      console.error(`[createFolder]: `, err);
    });
  },

  //-------------------------------------------------------------------
  // Delete Item
  //-------------------------------------------------------------------
  async deleteItem(context, payload = { item: null, driveId: '', path:'', service: ''}) {
    const { item, service, path, driveId } = payload;
    const commitDelete = () => context.commit('DELETE_LIST_ITEM', { asset_id: item.id, driveId: driveId, path: path, service: service });
    const commitFeatureDelete = () => context.commit('DELETE_FEATURE_LIST_ITEM', { asset_id: item.id, service: service });

    console.log(payload)
    if (!item || !service)
      throw Error (`item & service are required\nitem: ${item.id}\nservice: ${service}`);

    if(service == 'shortcuts') {
      await Drivey.removeShortcuts({id: item.id}).then(() => {
          commitFeatureDelete();
          //context.dispatch('getDrive', { path: '', service: service, prefix: '' });
          context.dispatch('getTracking', {});
          context.commit('SET_TOAST', { message: 'Item successfully deleted.', position: 'bottom-right', type: 'success', duration: 5000 })
      }).catch((err) => {
        console.log('err', err)
        context.commit('SET_TOAST', { message: err.message, position: 'top-right', type: 'error', duration: 5000 });
        console.error(`[deleteItem]: `, err);
      });
    } else {
      await Drivey.deleteItem({service: service, driveId: driveId, asset_id: item.id}).then(() => {  
          commitDelete();
          //context.dispatch('')
          //context.dispatch('getDrive', { path: '', service: service, prefix: '' });
          context.dispatch('getTracking', {});
          context.commit('SET_TOAST', { message: 'Item successfully deleted.', position: 'bottom-right', type: 'success', duration: 5000 })
      }).catch((err) => {
        console.log('err', err)
        context.commit('SET_TOAST', { message: err.message, position: 'top-right', type: 'error', duration: 5000 });
        console.error(`[deleteItem]: `, err);
      });
    }
  },

  //-------------------------------------------------------------------
  // Monitor Drive
  //-------------------------------------------------------------------
  async monitorDrive(_context, payload = { drive: '', data: null }) {
    try {
      const { drive, data } = payload;

      switch (drive) {
        case 'ONEDRIVE': {
          return await fetch(`https://api.onedrive.com/monitor/${data}`);
        } default: {
          throw Error(`invalid drive name, ${drive}`);
        }
      }
    } catch (err) {
      console.error(`[monitorDrive]: `, err);
    }
  },

  //-------------------------------------------------------------------
  // Copy Item
  //-------------------------------------------------------------------
  /*async copyItem(context, payload = { item: null, drive: '', destination: {}}) {
    console.log('copy item', payload)
    const { item, drive, destination } = payload;

    this.copyItemTo(item, drive, destination, destination);

    /*if (!item || !drive)
        throw Error (`item & drive are required\nitem: ${item}\ndrive: ${drive}`);

    try {
      switch (drive) {
        case 'ONEDRIVE': {
          await onedrive.copyDriveItem(item, destination.id, destination.driveId );
          context.dispatch('getDrive', { path: '', drive: drive, prefix: '' });
          context.commit('SET_TOAST', { message: 'Item copied successfully.', position: 'bottom-right', type: 'success', duration: 5000 });
          context.dispatch('getTracking', {});
          break;
        }
        case 'GOOGLEDRIVE': {
          await googleAuth.copyItem(item.id).then(() => {
            
          }).catch((err) => {
            console.log('err', err)
          });
          context.dispatch('getTracking', {});
          break;
        }
        case 'DROPBOX': {
          await Drivey.copyItem({ service: 'dropbox', dest_service:'dropbox', name:'', newName: item.name});

          // await dropbox.copyDriveItem(item.path_lower).then(() => {
          //   context.dispatch('getDrive', { path: '', drive: drive, prefix: '' });
          // });
          context.dispatch('getTracking', {});
          break;
        }
        case 'DROPBOXBUSINESS': {
          await dropboxbusiness.copyDriveItem(item.path_lower).then(() => {
            context.dispatch('getDrive', { path: '', drive: drive, prefix: '' });
          });
          context.dispatch('getTracking', {});
          break;
        }
        case 'DRIVEY':
          await Drivey.copyItem({ service: 'drivey', dest_service:'drivey', name:'', newName: item.name});
          context.dispatch('getTracking', {});
          break;
        case 'AWS':
          await Drivey.copyItem({service: 's3', dest_service:'s3', name:'', newName: item.name});
          context.dispatch('getTracking', {});
          break;
        default: {
          throw Error(`invalid drive name, ${payload.drive}`);
        }
      }
    } catch (err) {
      
    }
    console.dir(item);
    console.dir(destination);
    await Drivey.copyItem({ service: service, dest_service: destination.driveId, bucket: destination.bucket, id: item.id, folder_id: item.folder_id}).then(res => {
      resolve(res.data)
      context.dispatch('getDrive', { path: destination_path.path, drive: drive, prefix: '' });
      context.dispatch('getTracking', {});
    }).catch(err => {
      context.commit('SET_TOAST', { message: err.message, position: 'top-right', type: 'error', duration: 5000 });
      console.error(`[copyItem]: `, err);
    });*
  },*/

  //-------------------------------------------------------------------
  // Select Item
  //-------------------------------------------------------------------
  async selectItem(context, payload = { item: null }) {
    context.commit('COPY_FILE_ITEM', { item: payload.item });
    context.commit('MOVE_FILE_ITEM', { item: payload.item });
  },

  //-------------------------------------------------------------------
  // Favorite List Items
  //-------------------------------------------------------------------
  /*async favoriteList(context, payload = { drive: ''}) {
    let { drive } = payload;
    drive = 'FAVORITES'
    let recs = '';
    recs = await Drivey.favoriteList();

    context.commit('SET_FEATURES',[{ [drive] : recs }, false]);
  },*/

  //-------------------------------------------------------------------
  // Encrypt List Items
  //-------------------------------------------------------------------
  /*async encryptList(context, payload = { drive: ''}) {
    let { drive } = payload;
    drive = 'ENCRYPTED'
    let recs = '';
    recs = await Drivey.encryptList();

    context.commit('SET_FEATURES',[{ [drive] : recs }, false]);
  },*/

  //-------------------------------------------------------------------
  // Upload file
  //-------------------------------------------------------------------
  async uploadItem(context, payload = { file: null, service: '', path: ''}) {
    const { file, service, path } = payload;

    console.log('--upload');
    /*if(!(file instanceof 'File')) newFile = file.getAsFile();
    else newFile = file;*/
    return new Promise((resolve, reject) => {
      //let reader = new FileReader();
      //reader.readAsDataURL(file);
      //reader.onload = function(readEvent) {
      //console.log(readEvent.target.result);
      console.log(`… file.name = ${file.name}`);
      console.dir(file)
      Drivey.uploadItem({service: service, path: path, file: file }).then(res => {
        console.dir(res.data);
        if(res && res.code == 'Set Error') {
          context.commit('SET_TOAST', { message: res.message, position: 'top-right', type: 'error', duration: 5000 });
          alert('reject')
          reject(res.data)
        } else {
          resolve(res.data)
          console.dir(this.drives[service].filter(drive => drive.default_drive == 1)[0].id)
          console.dir(this.listFiles)
          console.log('---upload item')
          //context.dispatch('getDrive', { path: path, drive: payload.service, prefix: '' });
          //this.getDrive({ path: path, service: payload.service,  prefix: '' })
          context.dispatch('getTracking', {});
        }
      }).catch(err => {
        reject(err)
      })
      //}
    });
  },

  //-------------------------------------------------------------------
  // Upload folder
  //-------------------------------------------------------------------
  async uploadItemFolder(context, payload = { file: null, service: '', path: ''}) {
    const { file, service, path } = payload;
    
    return new Promise((resolve, reject) => {
      let reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = function(readEvent) {
        console.log(readEvent.target.result);
        console.log(`… file.name = ${file.name}`);
        Drivey.uploadItemFolder({service: service, file: {name:file.name, file: readEvent.target.result, lastModified: file.lastModified, size: file.size, type: file.type, webkitRelativePath: file.webkitRelativePath}, path: path}).then(res => {
          if(res && res.code == 'Set Error') {
            context.commit('SET_TOAST', { message: res.message, position: 'top-right', type: 'error', duration: 5000 });
            reject(res.data)
          } else {
            resolve(res.data)
            context.dispatch('getDrive', { path: path, drive: payload.service, prefix: '' });
            context.dispatch('getTracking', {});
          }
        }).catch(err => {
          reject(err)
        })
      }
    });
  },

  //-------------------------------------------------------------------
  // Encrypt Item Upload
  //-------------------------------------------------------------------
  async encryptUpload(context, payload = { file: null, service: '', path: ''}) {
    const { file, service, path } = payload;

    console.dir(file);
    return new Promise((resolve, reject) => {
      let reader = new FileReader();
      console.dir(file);
      reader.readAsDataURL(file);
      reader.onload = function(readEvent) {
        console.log(readEvent.target.result);
        console.log(`… file.name = ${file.name}`);
        Drivey.uploadItem({service: service, file: [{name:file.name, file: readEvent.target.result, lastModified: file.lastModified, size: file.size, type: file.type, webkitRelativePath: file.webkitRelativePath}]}).then(res => {
          console.dir(res.data);
          if(res && res.code == 'Set Error') {
            context.commit('SET_TOAST', { message: res.message, position: 'top-right', type: 'error', duration: 5000 });
            alert('reject')
            reject(res.data)
          } else {
            resolve(res.data)
            context.dispatch('getDrive', { path: path, drive: payload.service, prefix: '' });
            context.dispatch('getTracking', {});
          }
        }).catch(err => {
          reject(err)
        })
      }
      Drivey.encryptUpload({file: file, service: service, path: path}).then(res => {
        console.dir(res);
        console.dir('encrypt--')
        if(res && res.code == 'Set Error') {
          context.commit('SET_TOAST', { message: res.message, position: 'top-right', type: 'error', duration: 5000 });
          reject(res.data)
        } else {
          resolve(res.data)
          context.dispatch('getTracking', {});
        }
      }).catch(err => {
        reject(err)
      })
    });
  },

  //-------------------------------------------------------------------
  // Download Item
  //-------------------------------------------------------------------
  async downloadItem(_context, payload = { item: null, service: '' }) {
    const { item, service } = payload;
    var fileDrivey;
    //var aDrivey;
    store.commit('IS_LOADING', true);
    if (!item)
      throw Error (`item is missing`, item);

    
    /*switch (drive) {
      case 'ONEDRIVE': {
        service = 'onedrive';
        break;
      }
      case 'GOOGLEDRIVE' : {
        service = 'google';
        break;
      }
      case 'AWS' : {
        service = 'aws';
        break;
      }
      case 'DRIVEY' : {
        service = 'drivey';
        break;
      }
      case 'DROPBOX' : {
        service = 'dropbox';
        break;
      }
      case 'DROPBOXBUSINESS' : {
        service = 'dropboxbusiness';
        break;
      }
      default: {
        store.commit('IS_LOADING', false);
        throw Error(`invalid drive name, ${drive}`);
      }
    }*/
    console.dir(item);
    await Drivey.downloadItem({service: service, id: item.id}).then((res) => {
      if(res && res.code == 'Set Error') {
        _context.commit('SET_TOAST', { message: res.message, position: 'top-right', type: 'error', duration: 5000 });
        throw Error(res)
      } else {
        fileDrivey = res.data
      
        console.dir(res.data)
        /*aDrivey = document.createElement("a");
        aDrivey.href = window.URL.createObjectURL(fileDrivey);
        aDrivey.download = item.name;
        aDrivey.setAttribute('target', '_blank');
        aDrivey.click();*/
        window.open(window.URL.createObjectURL(fileDrivey));
        store.commit('IS_LOADING', false);
        return res
      }
    }).catch((err) => {
      console.error(`[downloadItem]: `, err);
      store.commit('IS_LOADING', false);
    })
  },

  //-------------------------------------------------------------------
  // Encrypt Download Item
  //-------------------------------------------------------------------
  async encryptDownload(context, item) {
    var response = [];
    context.commit('SET_TOAST', { message: 'ENCRYPT DOWNLOADING.', position: 'bottom-right', type: 'success', duration: 5000 })
    response = await Drivey.downloadEncypted(item.id);

    if(response.code && response.code == 'Set Error') {
      context.commit('SET_TOAST', { message: response.message, position: 'top-right', type: 'error', duration: 5000 });
    } else {
      const blob = await response.blob();
      const newBlob = new Blob([blob]);
      const blobUrl = window.URL.createObjectURL(newBlob);
      const link = document.createElement('a');
      link.href = blobUrl;
      link.setAttribute('download', `${item.name}`);
      document.body.appendChild(link);
      link.click();
      link.parentNode.removeChild(link);
      window.URL.revokeObjectURL(blobUrl);
      context.commit('SET_TOAST', { message: 'ENCRYPT DOWNLOAD SUCCESS.', position: 'bottom-right', type: 'success', duration: 5000 })
      context.dispatch('getDrive', { path: '', drive: 'ENCRYPTED', prefix: '' });
    }
  },

  //-------------------------------------------------------------------
  // Unencrypt Item
  //-------------------------------------------------------------------
  async unencryptItem(context, item) {
    context.commit('SET_TOAST', { message: 'ENCRYPT STARTED.', position: 'bottom-right', type: 'success', duration: 5000 })
    await Drivey.unencryptFile(item.id).then(async res => {
        const blob = await res.blob();
        const newBlob = new Blob([blob]);
        const blobUrl = window.URL.createObjectURL(newBlob);
        const link = document.createElement('a');
        link.href = blobUrl;
        link.setAttribute('download', `${(item.name.endsWith('.aes') ? item.name.replace(/.aes$/, "") : item.name)}`);
        document.body.appendChild(link);
        link.click();
        link.parentNode.removeChild(link);
        window.URL.revokeObjectURL(blobUrl);
        context.commit('SET_TOAST', { message: 'ENCRYPT SUCCESS.', position: 'bottom-right', type: 'success', duration: 5000 })
        context.dispatch('getDrive', { path: '', drive: 'ENCRYPTED', prefix: '' });
    });
  },

  //-------------------------------------------------------------------
  // Favorite Item
  //-------------------------------------------------------------------
  async favoriteItem(context, items) {
    //const items = items;
    let drive;
    context.commit('SET_TOAST', { message: 'ADDING TO FAVORITES.', position: 'bottom-right', type: 'success', duration: 5000 })
    console.dir(items);
    for(let item of items) {
      /*switch (item.service) {
        case 'onedrive': {
          drive = 'ONEDRIVE';
          break;
        }
        case 'google' : {
          drive = 'GOOGLEDRIVE';
          break;
        }
        case 'dropbox': {
          drive = 'DROPBOX';
          break;
        }
        case 'dropboxbusiness': {
          drive = 'DROPBOXBUSINESS';
          break;
        }
        case 'drivey':
          drive = 'DRIVEY';
          break;
        case 's3': {
          drive = 'AWS';
          break;
        } default: {
          throw Error(`invalid drive name, ${drive}`);
        }
      }*/
      await Drivey.favorite({'asset_id': item.id, 'drive_id': item.drive_id}).then(res => { 
        if(res && res.code == 'Set Error') {
          context.commit('SET_TOAST', { message: res.message, position: 'top-right', type: 'error', duration: 5000 });
          throw Error(res)
        } else {
          console.dir(item);
          context.commit('SET_TOAST', { message: 'ADDED TO FAVORITES.', position: 'bottom-right', type: 'success', duration: 5000 })
          context.dispatch('getDrive', { path: '', drive: drive, prefix: '' });
        }
      });
    }
  },

  //-------------------------------------------------------------------
  // Unfavorite Item
  //-------------------------------------------------------------------
  async unfavoriteItem(context, items) {
    for(let item of items) {
      context.commit('SET_TOAST', { message: 'REMOVING FROM FAVORITES.', position: 'bottom-right', type: 'success', duration: 5000 })
      await Drivey.unfavorite(item.id).then(res => { 
        if(res && res.code == 'Set Error') {
          context.commit('SET_TOAST', { message: res.message, position: 'top-right', type: 'error', duration: 5000 });
          throw Error(res)
        } else {
          context.commit('SET_TOAST', { message: 'REMOVED FROM FAVORITES.', position: 'bottom-right', type: 'success', duration: 5000 })
          context.dispatch('getDrive', { path: '', drive: this.drive, prefix: '' });
        }
      });
    }
  },

  //-------------------------------------------------------------------
  // Unencrypt Item
  //-------------------------------------------------------------------
  async decryptItem(context, items) {
    for(let item of items) {
      context.commit('SET_TOAST', { message: 'DECRYPT STARTED.', position: 'bottom-right', type: 'success', duration: 5000 })
      await Drivey.decrypt(item.id).then(res => { 
        if(res && res.code == 'Set Error') {
          context.commit('SET_TOAST', { message: res.message, position: 'top-right', type: 'error', duration: 5000 });
          throw Error(res)
        } else {
          context.commit('SET_TOAST', { message: 'DECRYPT SUCCESS.', position: 'bottom-right', type: 'success', duration: 5000 })
          context.dispatch('getDrive', { path: '', drive: 'ENCRYPTED', prefix: '' });
        }
      });
    }
  },

  //-------------------------------------------------------------------
  // Encrypt Existing
  //-------------------------------------------------------------------
  async encryptFile(context, payload = {items: '', drive: ''}) {
    const { items, drive } = payload;
    for(let item of items) {
      context.commit('SET_TOAST', { message: 'ENCRYPT STARTED.', position: 'bottom-right', type: 'success', duration: 5000 })
      /*switch (drive) {
        case 'ONEDRIVE': {        
          res = await Drivey.encryptExisting(item.id, 'onedrive')
          break;
        }
        case 'GOOGLEDRIVE': {
          res = await Drivey.encryptExisting(item.id, 'google')
          break;
        }
        case 'DROPBOX': {
          res = await Drivey.encryptExisting(item, drive)
          break;
        }
        case 'DROPBOXBUSINESS': {
          res = await Drivey.encryptExisting(item, drive)
          break;
        }
        case 'AWS': {
          res = await Drivey.encryptExisting(item.id, 's3')
          break;
        }
        case 'DRIVEY': {
          res = await Drivey.encryptExisting(item.id, 'drivey')
          break;
        }
      }*/
      await Drivey.encryptExisting(item, drive).then(res => {
        if(res && res.code == 'Set Error') {
          context.commit('SET_TOAST', { message: res.message, position: 'top-right', type: 'error', duration: 5000 });
          throw Error(res)
        } else {
          context.commit('SET_TOAST', { message: 'ENCRYPT SUCCESS.', position: 'bottom-right', type: 'success', duration: 5000 })
        }
      }).catch(err => {
        context.commit('SET_TOAST', { message: err.message, position: 'top-right', type: 'error', duration: 5000 });
      })
    }
  },

  //-------------------------------------------------------------------
  // Encrypt Get Key
  //-------------------------------------------------------------------
  async encryptGetKey(context) {
    return new Promise((resolve, reject) => {
      Drivey.encryptGetKey().then(res => {
        if(res && res.code == 'Set Error') {
          context.commit('SET_TOAST', { message: res.message, position: 'top-right', type: 'error', duration: 5000 });
          reject(res.data)
        } else {
          resolve(res.data)
        }
      }).catch(err => {
        reject(err)
      })
    })
  },

  //-------------------------------------------------------------------
  // Loader
  //-------------------------------------------------------------------
  // state() {
  //   return {
  //     isLoading: false,
  //   };
  // },

  //-------------------------------------------------------------------
  // listBackup
  //-------------------------------------------------------------------
  /*async listBackUps(context, payload = { type: '' }) {
    //let { drive } = payload;
    //drive = 'backups';
    store.commit('IS_LOADING', true);
    await Drivey.listBackUps(payload).then(res => {
      if(res && res.code == 'Set Error') {
        context.commit('SET_TOAST', { message: res.message, position: 'top-right', type: 'error', duration: 5000 });
        throw Error(res)
      } else {
        store.commit('IS_LOADING', false);
        console.dir(res);
        console.log('-listbackups')
        context.commit('SET_FEATURES',[{ [this.service] : res.backups }, false]);
      }
    });
  },*/

  //-------------------------------------------------------------------
  // addBackup
  //-------------------------------------------------------------------
  async addBackUp(context, payload) {
    store.commit('IS_LOADING', true);
    console.dir(payload)
    let backup_id = null;
    await Drivey.addBackUps(payload).then(res => {
      if(res && res.code == 'Set Error') {
        context.commit('SET_TOAST', { message: res.message, position: 'top-right', type: 'error', duration: 5000 });
        throw Error(res)
      } else {
        //context.dispatch('listBackUps');
        context.dispatch('getTracking', {});
        // context.commit('SET_TOAST', { message: 'Backup Created', position: 'bottom-right', type: 'success', duration: 5000 });
        store.commit('IS_LOADING', false);
        console.dir(res)
        console.log('actions addbackup')
        backup_id = res.backup_id;

      }
    });
    if(backup_id) {
      alert('123 backup me')
      /*return await this.addBackUpFiles({files: payload.files, drive_id: payload.files_drive_id}).then(res => {
        console.dir(res)
        return res
      })*/
      context.commit('SET_TOAST', { message: 'BACKUP STARTED', position: 'bottom-right', type: 'success', duration: 5000 });
      return await Drivey.addBackUpsFiles({backup_id: backup_id, files: payload.files, drive_id: payload.files_drive_id}).then(res => { 
        if(res && res.code == 'Set Error') {
          context.commit('SET_TOAST', { message: res.message, position: 'top-right', type: 'error', duration: 5000 });
          throw Error(res)
        } else {
          //context.dispatch('listBackUps');
          console.dir(res)
          context.dispatch('getTracking', {});
          context.commit('SET_TOAST', { message: 'BACKUP CREATED', position: 'bottom-right', type: 'success', duration: 5000 });
          return res;
        }
      });
    }
  },

  //-------------------------------------------------------------------
  // addBackupFiles
  //-------------------------------------------------------------------
  async addBackUpFiles(context, payload) {
    console.log('start backup payload', payload)
    context.commit('SET_TOAST', { message: 'BACKUP STARTED', position: 'bottom-right', type: 'success', duration: 5000 });
    await Drivey.addBackUpsFiles(payload).then(res => { 
      if(res && res.code == 'Set Error') {
        context.commit('SET_TOAST', { message: res.message, position: 'top-right', type: 'error', duration: 5000 });
        throw Error(res)
      } else {
        //context.dispatch('listBackUps');
        console.dir(res)
        context.dispatch('getTracking', {});
        context.commit('SET_TOAST', { message: 'BACKUP CREATED', position: 'bottom-right', type: 'success', duration: 5000 });
        return res;
      }
    });
  },

  //-------------------------------------------------------------------
  // deleteBackup
  //-------------------------------------------------------------------
  async deleteBackUp(context, payload) {
    context.commit('SET_TOAST', { message: 'DELETING BACKUP', position: 'bottom-right', type: 'success', duration: 5000 });
    await Drivey.deleteBackUps(payload).then(res => {
      if(res && res.code == 'Set Error') {
        context.commit('SET_TOAST', { message: res.message, position: 'top-right', type: 'error', duration: 5000 });
        throw Error(res)
      } else {
        context.dispatch('listBackUps');
        context.dispatch('getTracking', {});
        context.commit('SET_TOAST', { message: 'BACKUP DELETED', position: 'bottom-right', type: 'success', duration: 5000 });
      }
    });
  },

  //-------------------------------------------------------------------
  // downloadBackup
  //-------------------------------------------------------------------
  async downloadBackup(context, backup_id) {

    try{
      context.commit('SET_TOAST', { message: 'DOWNLOAD STARTED', position: 'bottom-right', type: 'success', duration: 5000 });
      var response = await Drivey.downloadBackup(backup_id)
      if(response.code && response.code == 'Set Error') {
        context.commit('SET_TOAST', { message: response.message, position: 'top-right', type: 'error', duration: 5000 });
        throw Error(response.message)
      } else {
        var backupData = await response.data;

        if(backupData.code === "Transfer Error"){
          context.commit('SET_TOAST', { message: backupData.message, position: 'top-right', type: 'error', duration: 5000 });
          return 'upgrade'
        } else {
          var aDrivey = document.createElement("a");
          aDrivey.href = window.URL.createObjectURL(backupData);
          aDrivey.download = name;
          aDrivey.click();
          context.commit('SET_TOAST', { message: 'DOWNLOAD SUCCESS', position: 'bottom-right', type: 'success', duration: 5000 });
        }
      }
    } catch (err) {
      context.commit('SET_TOAST', { message: err.message, position: 'top-right', type: 'error', duration: 5000 });
    }
  },

  //-------------------------------------------------------------------
  // Pause Backup
  //-------------------------------------------------------------------
  async pauseBackups(context, id) {
    context.commit('SET_TOAST', { message: 'PAUSING BACKUP', position: 'bottom-right', type: 'success', duration: 5000 });
    await Drivey.pauseBackup(id).then(res => {  
      if(res && res.code == 'Set Error') {
        context.commit('SET_TOAST', { message: res.message, position: 'top-right', type: 'error', duration: 5000 });
        throw Error(res)
      } else {
        context.dispatch('getTracking', {});
        context.commit('SET_TOAST', { message: 'BACKUP PAUSED', position: 'bottom-right', type: 'success', duration: 5000 });
      }
    });
  },

  //-------------------------------------------------------------------
  // Resume Backup
  //-------------------------------------------------------------------
  async resumeBackups(context, id) {
    context.commit('SET_TOAST', { message: 'RESUMING BACKUP', position: 'bottom-right', type: 'success', duration: 5000 });
    await Drivey.resumeBackup(id).then(res => {
      if(res && res.code == 'Set Error') {
        context.commit('SET_TOAST', { message: res.message, position: 'top-right', type: 'error', duration: 5000 });
        throw Error(res)
      } else {
        context.dispatch('getTracking', {});
        context.commit('SET_TOAST', { message: 'BACKUP RESUMED', position: 'bottom-right', type: 'success', duration: 5000 })
      }
    });
  },

  //-------------------------------------------------------------------
  // Add Shortcut
  //-------------------------------------------------------------------
  async addShortcuts(context, payload = { name: '', drive_dest: '', drive_id_dest: '', asset_id: '', drive_id: '', url: '', custom: false }) {
    console.log('ashortcuts', payload.drive)
    
    try {
      const { name, drive_dest, drive_id_dest, asset_id, drive_id, url, custom } = payload

      if(!name|| !asset_id || !drive_id) {
        if(!custom) {
          throw Error (`asset_id, name & drive_id are required\nname: ${name}\nasset_id: ${asset_id}\ndrive: ${drive_id}`);
        }
      }
      context.commit('SET_TOAST', { message: 'ADDING SHORTCUT.', position: 'bottom-right', type: 'success', duration: 5000 })
      await Drivey.addShortcuts({ name: name, drive_dest: drive_dest, drive_id_dest: drive_id_dest, asset_id: asset_id, drive_id: drive_id, url: url }).then(res => {
        console.dir(res);
        if(res && res.code == 'Set Error') {
          context.commit('SET_TOAST', { message: res.message, position: 'top-right', type: 'error', duration: 5000 });
          throw Error(res)
        } else {
          if(drive_id){
            context.dispatch('getDrive', { path: '', drive: drive_id, prefix: '' });
          } else {
            context.dispatch('listShortcuts')
          }
          context.commit('SET_TOAST', { message: 'SHORTCUT ADDED.', position: 'bottom-right', type: 'success', duration: 5000 })
        }
      });

      // switch (drive) {
      //   case 'ONEDRIVE': {
      //     await Drivey.shortcutsAdd({ name: name, drive_dest: drive_dest, drive_id_dest: drive_id_dest, asset_id: asset_id, drive_id: drive_id, url: url })
      //     context.dispatch('getDrive', { path: '', drive: drive, prefix: '' });
      //     context.commit('SET_TOAST', { message: 'Shortcut successfully added.', position: 'bottom-right', type: 'success', duration: 5000 })
      //     break;
      //   }
      //   case 'GOOGLEDRIVE': {
      //     await Drivey.shortcutsAdd({ name: name, drive_dest: drive_dest, drive_id_dest: drive_id_dest, asset_id: asset_id, drive_id: drive_id, url: url })
      //     context.dispatch('getDrive', { path: '', drive: drive, prefix: '' });
      //     context.commit('SET_TOAST', { message: 'Shortcut successfully added.', position: 'bottom-right', type: 'success', duration: 5000 })
      //     break;
      //   }
      //   case 'DROPBOX': {
      //     await Drivey.shortcutsAdd({ name: name, drive_dest: drive_dest, drive_id_dest: drive_id_dest, asset_id: asset_id, drive_id: drive_id, url: url })
      //     context.dispatch('getDrive', { path: '', drive: drive, prefix: '' });
      //     context.commit('SET_TOAST', { message: 'Shortcut successfully added.', position: 'bottom-right', type: 'success', duration: 5000 })
      //     break;
      //   }
      //   case 'AWS': {
      //     await Drivey.shortcutsAdd({ name: name, drive_dest: drive_dest, drive_id_dest: drive_id_dest, asset_id: asset_id, drive_id: drive_id, url: url })
      //     context.dispatch('getDrive', { path: '', drive: drive, prefix: '' });
      //     context.commit('SET_TOAST', { message: 'Shortcut successfully added.', position: 'bottom-right', type: 'success', duration: 5000 })
      //     break;
      //   }
      //   case 'DRIVEY': {
      //     await Drivey.shortcutsAdd({ name: name, drive_dest: drive_dest, drive_id_dest: drive_id_dest, asset_id: asset_id, drive_id: drive_id, url: url })
      //     context.dispatch('getDrive', { path: '', drive: drive, prefix: '' });
      //     context.commit('SET_TOAST', { message: 'Shortcut successfully added.', position: 'bottom-right', type: 'success', duration: 5000 })
      //     break;
      //   }
      //   default: {
      //     throw Error(`invalid drive name, ${drive}`);
      //   }
      // }

    } catch (err) {

      context.commit('SET_TOAST', { message: err.message, position: 'top-right', type: 'error', duration: 5000 });
      console.error(`[addShortcuts]: `, err);
    }
  },

  //-------------------------------------------------------------------
  // Remove Shortcut
  //-------------------------------------------------------------------
  async removeShortcuts(context, id) {
    context.commit('SET_TOAST', { message: 'REMOVING SHORTCUT.', position: 'bottom-right', type: 'success', duration: 5000 })
    await Drivey.shortscutsRemove(id).then(res => {
      if(res && res.code == 'Set Error') {
        context.commit('SET_TOAST', { message: res.message, position: 'top-right', type: 'error', duration: 5000 });
        throw Error(res)
      } else {
        context.commit('SET_TOAST', { message: 'SHORTCUT REMOVED.', position: 'bottom-right', type: 'success', duration: 5000 })
      }
    });
  },

  //-------------------------------------------------------------------
  // List Shortcuts
  //-------------------------------------------------------------------
  /*async listShortcuts(context, payload = { drive: '' }) {
    let { drive } = payload;
    drive = 'SHORTCUTS'
    let recs = '';
    store.commit('IS_LOADING', true);
    recs = await Drivey.shortcutList().then(res => {
      if(res && res.code == 'Set Error') {
        context.commit('SET_TOAST', { message: res.message, position: 'top-right', type: 'error', duration: 5000 });
        throw Error(res)
      } else {
        context.commit('SET_FEATURES',[{ [drive] : recs }, false]);
        store.commit('IS_LOADING', false);
      }
    });
  },*/
  clearSelected(context, payload) {
    store.commit('IS_LOADING', true);
    console.log('clear selected action', payload)
    context.commit('CLEAR_SELECTED', payload)
    store.commit('IS_LOADING', false);
  }
}

// q: what does the GET_DRIVES mutation do?
// a: it sets the drives state to the payload.drives

// q: write a function to get the drives
// a: async getDrives(context) {
//    await Drivey.getDrives().then(res => {
//      context.commit('GET_DRIVES', res.data);
//    });
//  }


//===============================================================================
// Mutations
//===============================================================================
const mutations = {
  'GET_DRIVES'(state, payload) {
    console.dir(payload.drives);
    state.drives = payload.drives
  },
  'CREATE_LIST_ITEM'(state, payload) {
    const { service, item, driveId, path } = payload;
    console.dir('createitem', item)
    console.dir('createitem', path)
    console.dir('createitem', service)
    console.dir('createitem', state.list)
    console.dir('createitem', state.list[service][driveId][path])
    console.log('---state list')
    if (service && item && driveId)
      state.list = state.list[service][driveId][path].unshift(item);
      console.dir(state.list[service][driveId][''])
    
  },
  'RENAME_LIST_ITEM'(state, payload) {
    const { name, service, asset_id } = payload; 
    console.dir(state)
    console.dir(state.list)
    console.log(service)
    const i = state.list[service][''].findIndex(e => e.id === asset_id);

    if (i >= 0 && name)
      state.list[service][''][i].name = name;
  },
  'DELETE_LIST_ITEM'(state, payload) {
    const { service, driveId, path, asset_id } = payload; 
    console.dir(payload)
    const i = state.list[service][driveId][path].findIndex(e => e.id === asset_id);
console.dir(state.list[service][driveId][path])
console.dir(state.list[service][driveId][path].findIndex(e => e.id === asset_id))
    if (i >= 0)
      state.list = state.list[service][driveId][path].splice(i, 1);
  },
  'DELETE_FEATURE_LIST_ITEM'(state, payload) {
    const { service, asset_id } = payload; 
    const i = state.featuresList[service][''].findIndex(e => e.id === asset_id);

    if (i >= 0)
      state.featuresList[service][''].splice(i, 1);
  },
  'SET_LIST'(state, [service, id, path, list, loading]) {
    let bread = (path) ? path : '';
    state.listLoading = loading
    /*console.log(state.list)
    console.log(list)
    console.log(service)
    console.log(id)
    console.log(path)
    console.dir( list )*/
    console.dir(list);
    console.dir(state.list)
    console.log(id)
    console.log(path)
    //console.dir({ ...state.list[service][id], ...{ [service] : { [id] : { [path] : list } } } })
    console.log('---setting the list---')
    if(id) state.list = { ...state.list, ...{ [service]: { [id] : { [bread] : list } } } } //mergeRecursive(state.list, list)
    //console.dir(Object.assign(state.list[service][id], { [path] : list }))
    //Object.assign(state.list[service][id], { [path] : list })
    console.log(service)
    console.dir(state.list)
    console.dir({ [service] : { [id] : { [bread] : list } } })
    //if(state.list[service][id]) Object.assign(state.list[service], { [id] : { [path] : list } })
    //else 
    //state.list = state.list[service][id] = { [path] : list }
    console.dir(state.list)
    console.log('----state.list')
  },
  'ADD_LIST_ITEM'(state, [service, id, path, file]) {
    console.dir(file)
    let bread = (path) ? path : '';
    console.log('list files state..')
    console.dir(state.list)
    console.dir({ [service]: { [id] : { [bread] : file } } })
    state.list = state.list[service][id][bread].unshift(file)
    //if(id) state.list = { ...state.list, ...{ [service]: { [id] : { [bread] : file } } } }
    console.dir(state.list)
  }, 
  'SET_MODAL_LIST'(state, [modalList, loading]) {
    state.modalListLoading = loading
    state.modalList = { ...state.modalList, ...modalList }
  },
  'SET_FEATURES'(state, [featuresList, loading]) {
    state.featureListLoading = loading
    //alert('====set====')
    console.log('--set features')
    console.dir(featuresList)
    state.featuresList = { ...state.featuresList, ...featuresList }
  },
  'SET_SEARCH_LIST'(state, searchListFiles) {
    state.searchListFiles = searchListFiles
  },
  'COPY_FILE_ITEM' (state, payload) {
    state.itemToCopy = payload.item;
  },
  'MOVE_FILE_ITEM' (state, payload) {
    state.itemToMove = payload.item;
  },
  'SET_BACKUPS' (state, payload) {
    state.backups = payload;
  },
  'SET_ROOT' (state, list) {
    state.list = { ...state.list, ...list };
  },
  'SETTINGS_DRIVE_MODAL_CLOSE'(state, payload) {
    state.settingsDrivesModalClosed = payload;
  },
  'SET_BANDWIDTH_STORAGE'(state, payload) {
    if(payload.data){
      payload.data.allocated['storage'] = Number(payload.data.allocated['storage']).toFixed(2).replace(/[.,]00$/, "");
      payload.data.used['storage'] = Number(payload.data.used['storage']).toFixed(2).replace(/[.,]00$/, "");
      payload.data.allocated['bandwidth'] = Number(payload.data.allocated['bandwidth']).toFixed(2).replace(/[.,]00$/, "");
      payload.data.used['bandwidth'] = Number(payload.data.used['bandwidth']).toFixed(2).replace(/[.,]00$/, "");
    }
    state.bandwidthStorage = payload.data;
  },
  'CLEAR_SELECTED'(state, payload) {
    state.clearSelectedItems = payload
  },
  'IS_LOADING'(state, payload) {
    state.isLoading = payload
  },
  'SIDEBAR_VISIBLE'(state, payload) {
    state.sidebarVisible = payload
  },
  'LAST_SORT'(state, payload) {
    state.lastSort = payload
  },
  /*'ITEM_MENU'(state, [featuresList, loading]) {
    state.listLoading = loading
    state.featuresList = { ...state.featuresList, ...featuresList }
  },*/
  //-------------------------------------------------------------------
  // Reset State
  //-------------------------------------------------------------------
  [RESET_STATE](state) {
    state = {
      drives: {

      }
    }
    Object.keys(initialState).forEach(key => { state[key] = initialState[key] })
  }
}

//===============================================================================
// Getters
//===============================================================================
const getters = {
  drives: state => state.drives,
  listFiles: state => state.list,
  modalListFiles: state => state.modalList, 
  listLoading: state => state.listLoading,
  featureListLoading: state => state.featureListLoading,
  featureDrives: state => state.featuresList,
  searchListFiles: state => state.searchList,
  itemToCopy: state => state.itemToCopy,
  itemToMove: state => state.itemToMove,
  backups: state => state.backups,
  driveRoot: state => state.root,
  settingsDrivesModalClosed: state => state.settingsDrivesModalClosed,
  bandwidthStorage: state => state.bandwidthStorage,
  clearSelectedItems: state => state.clearSelectedItems, 
  isLoading: state => state.isLoading, 
  sidebarVisible: state => state.sidebarVisible, 
  lastSort: state => state.lastSort
}

export default {
  state,
  actions,
  mutations,
  getters
};
