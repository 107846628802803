//===============================================================================
// Imports
//===============================================================================
import { 
  RESET_STATE
} from '../mutations.type'

//===============================================================================
// Initial State
//===============================================================================
const initialState = [
  // example:
  // {
  //   id: '',
  //   action: 'DELETE',
  //   continue: false
  // }
];

const state = initialState;

//===============================================================================
// Actions
//===============================================================================
const actions = {
  /**
   * @description Helper function to delay a drive action that is cancelable within a time frame, to cancel use undoAction
   * @param {object} state
   * @param {object} payload
   * @param {string} payload.id drive item's id necessary for cancellation
   * @param {'DELETE' | 'MOVE' | 'RENAME' | 'DOWNLOAD' | 'UPLOAD' | 'ENCRYPT'} payload.action keyword for type of drive action called
   * @param {number} payload.seconds default 6 sec
   * @returns {boolean}
   */
  async delayAction({ state }, payload = { id: '', action: '', seconds: 6 }) {
    const { id, action, seconds } = payload;
    const allowedActions = ['DELETE', 'MOVE', 'COPY'];

    if (!id || typeof id !== 'string')
      throw Error(`id required as type string`, id);

    if (!action || typeof action !== 'string')
      throw Error(`action required as type string`, action);

    if (seconds && typeof seconds !== 'number')
      throw Error(`seconds is optional, but must be type number`, seconds);

    if (!(new RegExp(allowedActions.join('|')).test(action)))
      throw Error(`action must match the following: ${`'` + allowedActions.join(`', '`) + `'`}`, action);
    
    const time = seconds ? (seconds * 1000) : 6000;
    let result = false;
      
    // if already found (user spammed action or a different action for the same item) - prevent multiple calls
    const i = state.findIndex(e => e.id === id);
    if (i >= 0)
      return result;

    state.push({ id: id, action: action, continue: true });

    const elem = state.find(e => e.id === id);

    if (elem)
      await new Promise(resolve => setTimeout(resolve, time))
        .then(() => result = Boolean(elem.action === action && elem.continue));

    for (const i in state)
      if (state[i].id === id)
        state.splice(i, 1);

    return result;
  },
  /**
   * @description Cancel a pending drive action by id before it has reach it's time limit
   * @param {string} id drive item's id necessary for cancellation
   */
  undoAction({ state }, id) {
    const i = state.findIndex(e => e.id === id);

    if (i >= 0)
      state[i].continue = false;
  }
}

//===============================================================================
// Mutations
//===============================================================================
const mutations = {
  //-------------------------------------------------------------------
  // Reset State
  //-------------------------------------------------------------------
  [RESET_STATE]() {
    Object.keys(initialState).forEach(key => { state[key] = initialState[key] })
  }
}

//===============================================================================
// Getters
//===============================================================================
const getters = {
  isActionPending: state => id => state.findIndex(e => e.id === id) >= 0 ? true : false,
  pendingActionType: state => id => {
    const found = state.find(e => e.id === id);
    return (found ? found.action : ''); 
  },
  recentlyDelayedId: state => {
    if (state && state.length)
      return state[state.length - 1].id;
    else
      return '';
  }
}

export default {
  state,
  actions,
  mutations,
  getters
};