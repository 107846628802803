//===============================================================================
// Imports
//===============================================================================
//import googleAuth from '@/services/Google/auth'
import { TokenService/*, storageService*/ } from '@/services/storage.service'
import { RESET_STATE } from '../mutations.type'
import { Drivey } from '@/services/Drivey'
import axios from "axios";
import store from "@/store";
import getDriveLink from '@/services/functions/getDriveLink';

//===============================================================================
// Initial State
//===============================================================================
const initialState = {
  service: 'drivey', 
  drives: {
    onedrive: [],
    google: [],
    dropbox: [],
    dropboxbusiness: [],
    icloud: [],
    drivey: [],
    aws: [],
    encrypted:[{
      id: null,
      accessToken: TokenService.getToken(),
      isSignedIn: true,
      isActive: true
    }],
    shortcuts: [{
      id: null,
      accessToken: TokenService.getToken(),
      isSignedIn: true,
      isActive: true
    }],
    backups: [{
      id: null,
      accessToken: TokenService.getToken(),
      isSignedIn: true,
      isActive: true
    }], 
    favorites: [{
      id: null,
      accessToken: TokenService.getToken(),
      isSignedIn: true,
      isActive: true
    }]
  },
  activeDrive: {}, 
  isLoading: false,
  isLoggedIn: !!TokenService.getToken(),
  isChromeExt: false
}

const baseUrl = process.env.VUE_APP_DRIVEY_BASE_URL

let settings = { headers: {
    'Content-Type': 'application/json',
    'Accept': 'application/json',
    // 'Bearer': TokenService.getToken()
  },
  method: 'POST',
}

const state = { ...initialState };

//===============================================================================
// Actions
//===============================================================================
const actions = {

  //-------------------------------------------------------------------
  // Login user
  //-------------------------------------------------------------------
  /**
   * @description Login to drive account specified by keyword
   * @param {object} context
   * @param {boolean}
   */

  async login(context, payload = { service: '', login: '', email: '', password: ''  }) {
    try {
      const { login, email, password, service } = payload;
      let userData, response;
      let token = null;
      let domain = window.location.href.split('/')[3];
      store.commit('IS_LOADING', true);
      //context.getters.drives(service);
      console.dir(state)
      if(!service) {
        response = Drivey.login({ email: email, password: password }).then(async (resp) => {
          if(resp && resp.access_token) {
            userData = { 
              accessToken: resp.access_token,
              redirect: '/drivey',
              service: 'drivey',
            };
            console.dir(resp);
            console.dir(userData);
            context.dispatch("driveyLogin", resp.access_token).then(async () => {
              await localStorage.setItem('userID', resp.user.id);
              //context.dispatch("storeUser", {drive: 'DRIVEY', userData: userData}).then(async () => {
              await store.dispatch('loginUserdata', { service: 'drivey' }).then(async () => {
                window.location = '/drivey';
              });           
              //});
            });
            /*await this.driveyLogin(resp.access_token).then(async (res) => {
              alert('driveylogin')
              console.dir(res)
              await localStorage.setItem('userID', resp.user.id);
              context.dispatch("storeUser", {drive: 'DRIVEY', userData: userData}).then(async () => {
                await store.dispatch('loginUserdata', { drive: 'DRIVEY' }).then(async () => {
                  alert('6')
                  window.location = '/drivey';
                });           
              });
            });*/
          } else if(resp.code && resp.code == 'Set Error') {
              context.commit('SET_TOAST', { message: resp.message, position: 'top-right', type: 'error', duration: 5000 });
              throw Error(resp.message)
          }
        }).catch(err => {
          console.dir(err);
          throw Error(err);
          //if(err.message) this.$toast.open({message: err.message, position: 'top-right', type: "error", duration: 5000});
          //else this.$toast.open({message: 'There was an error logging in. Please contact support', position: 'top-right', type: "error", duration: 5000});
          //return err;
       });
      } else if (service) {
        Drivey.ssoLogin({service:service}).then(resp => {
          if(login) {          
            Drivey.login(resp).then(async (res) => {
              if(res && res.access_token) {
                userData = { 
                  accessToken: res.access_token,
                  redirect: '/'+service,
                  service: service,
                };
                console.dir(resp);
                console.dir(userData);
                let link = this.getDriveLink(this.service.toLowerCase());
                context.dispatch("driveyLogin", resp.access_token).then(async () => {
                  await localStorage.setItem('userID', resp.user.id);
                  await context.dispatch("storeUser", {service: service, userData: userData});
                  await store.dispatch('loginUserdata', { service: service }).then(async () => {
                    window.location = '/'+link;
                  });           
                  //});
                });
                /*await this.driveyLogin(resp.access_token).then(async (res) => {
                  alert('driveylogin')
                  console.dir(res)
                  await localStorage.setItem('userID', resp.user.id);
                  context.dispatch("storeUser", {drive: 'DRIVEY', userData: userData}).then(async () => {
                    await store.dispatch('loginUserdata', { drive: 'DRIVEY' }).then(async () => {
                      alert('6')
                      window.location = '/drivey';
                    });           
                  });
                });*/
              } else if(res && res.message) {
                console.log('1111111')
                return res
              }
            });
          } else if(resp) {
            resp.account_id = localStorage.getItem('userID');
            console.dir(resp)
            Drivey.driveAdd(resp).then(async (res) => {
              console.dir(res);
            });
          }
        });
        console.log(userData);
        console.log(token);
        console.log(domain);
      }
      return response;
      //state.isChromeExt = (window.parent && window.parent.chrome && window.parent.chrome.runtime && window.parent.chrome.runtime.id) ? true : false
      /*
      const oauth = async (service) => {
        storageService.setItem('login', login !== undefined ? login : 0);
        //store.commit('IS_LOADING', true);
        axios.get(`${baseUrl}/api/v1/oauth/link?service=${service}`, settings).then((resp) => {
          /*if(!state.isChromeExt) window.location = resp.data
          else {
            console.dir(resp.data)*/
            /*let win = window.open(resp.data, "Drivey", "fullscreen=no")
            let int = setInterval(function() {
              console.dir(win)
              if(win.location && win.location.href && win.location.href.indexOf('&token') != -1) {
                console.log(win.location.href);
                token = win.location.href.slice(win.location.href.indexOf('&token=' +7), win.location.href.indexOf('&refresh'))
              }
            }, 500)
            if(token) {
              clearInterval(int)
              win.close()
            }* /
            let win = window.open(resp.data, "Drivey", "fullscreen=no,height=400,width=400,location=no,status=no,titlebar=no,toolbar=no,menubar=no,resizable=no")
            //let step = 1;
            let int = setInterval(function() {
              console.log(domain);
              console.dir(win.location);
              let params;
              let service;
              try {
                console.dir(params);
                if(win.location && win.location.search) {
                  console.log(win.location.search);
                  params = new URLSearchParams(win.location.search);
                  console.dir(params);
                  console.log('----params');
                }
                /*if(win.location && win.location.href && win.location.href.indexOf(domain) != -1) {
                  console.log('---redirect');
                  win.location.href = resp.data;
                  step = 2;
                } else if(step == 2 && params) {
                * /
                if(params) {
                  console.log('---token');
                  console.log(win.location);
                  token = params.get('token');
                  console.dir(token);
                  //store.commit('IS_LOADING', false);
                  TokenService.saveToken(token);
                  store.commit('token', token);
                  console.log(service);
                  switch (service) {
                    case 'GOOGLEDRIVE':
                      service = 'google';
                      break;
                    case 'DROPBOX':
                      service = 'dropbox';
                      break;
                    case 'DROPBOXBUSINESS':
                      service = 'dropboxbusiness';
                      break;
                    case 'ONEDRIVE':
                      service = 'onedrive';
                      break;
                    case 'BOX':
                      service = 'box';
                      break;
                  }
                  userData = { 
                    accessToken: token,
                    redirect: '/'+this.drive.toLowerCase(),
                    drive: service,
                  };
                  //store.commit('IS_LOADING', true);
                  context.dispatch("storeUser", {drive: drive, userData: userData}).then(async () => {
                    /*await store.dispatch('loginUserdata', { drive: drive }).then(async () => {
                      window.location = '/'+drive.toLowerCase();
                    });* /
                    window.location = '/'+drive.toLowerCase();
                    win.close()
                    clearInterval(int)           
                  });
                }
                if(win.closed) {
                  clearInterval(int)
                  store.commit('IS_LOADING', false);
                }
              } catch(e) {
                console.log('--err');
                console.log(e);
                console.dir(win);
                if(win.closed === true) store.commit('IS_LOADING', false);
              }
            }, 500)
        })
      };
      console.dir(drive);
      switch (drive) {
        case 'GOOGLEDRIVE':
          oauth('google');
          break;
        case 'DROPBOX':
          oauth('dropbox');
          break;
        case 'DROPBOXBUSINESS':
          oauth('dropboxbusiness');
          break;
        case 'ONEDRIVE':
          oauth('onedrive');
          break;
        case 'BOX':
          oauth('box');
          break;
        case 'ENCRYPTED' :
        case 'SHORTCUTS' :
        case 'BACKUPS' :
        {
          console.log('login ask')
          userData = {
            id: '',
            accessToken: TokenService.getToken()
          }
          //store.commit('IS_LOADING', true);
          await context.dispatch("storeUser", {drive: drive, userData: userData});
          //store.commit('IS_LOADING', false);
        }
          break;
        case 'ICLOUD':
          console.log('[login]:', drive);
          break;
        default:
          throw Error(`invalid drive name, ${drive}`);
      }*/
    } catch (err) {
      console.error(`[login]:`, err);
      //store.commit('IS_LOADING', false);
    }
  },

  async addAccount(context, payload = { service: '', name: '', account_id: '', access_key: '', access_secret: '', host: '', user: '', pass: ''  }) {
    const { service, account_id, name, access_key, access_secret, host, user, pass } = payload

    Drivey.addAccount({ service: service, name: name, account_id: account_id, access_key: access_key, access_secret: access_secret, host: host, user: user, pass: pass }).then(async (res) => {
      console.dir(res);
      return res;
    });
  }, 

  async loginConnect(context, payload = { service: '', token: '', refresh: '', account_id: '', code: '' }) {
    const { token, refresh, account_id, code, service } = payload;
    var userID = await localStorage.getItem('userID');
    let login = await localStorage.getItem('login');
    store.commit('IS_LOADING', true);
    let response = await axios.post(`${baseUrl}/api/v1/oauth/connect?id=${userID}&service=${service}&token=${token}&refresh=${refresh}&account_id=${account_id}&code=${code}&login=${login}`, '', settings);
    console.dir(response);
    if(response.status !== 200 || response.message === "Unauthenticated." || response.data.code === "Oauth Error") {
      store.commit('IS_LOADING', false);
      return false
    }
    store.commit('IS_LOADING', false);
    return response.data
  },

  async loginUserdata(context, payload = { service: '' }) {
    try {
      const { service } = payload;

      let userData;
      store.commit('IS_LOADING', true);
      await Drivey.getUserInfo().then(async getUserInfo => {
        console.dir();
        console.log('----get user info');
        if (!service || getUserInfo.message === "Unauthenticated.")
          throw Error(`error`);

        (async function() {
          if(getUserInfo && getUserInfo.drives) {
            for await (const driveData of getUserInfo.drives) {
              userData = driveData;
              userData = { ...userData,
                accessToken: userData.auth_token,
                redirect: '/'+driveData.provider_id,
                service: driveData.provider_id,
              };
              await context.dispatch("storeUser", {service: driveData.provider_id, userData: userData});
            }
          }
        })();

        await localStorage.setItem('userID', getUserInfo.id);
        await context.dispatch("login", {service: 'drivey', data: getUserInfo});
        store.commit('IS_LOADING', false);
      })
    } catch (err) {
      store.commit('IS_LOADING', false);
      return false
    }
  },

  async driveyLogin(context, payload) {
    console.dir(payload)
    await TokenService.saveToken(payload);
    await context.commit('SET_LOGIN_STATUS', true);
  }, 

  async setService(context, payload) {
    context.commit('SET_SERVICE', payload)
    context.commit('SET_ACTIVE_DRIVE', context.state.drives[context.state.service].filter(drive => drive.default_drive == 1)[0])
  }, 

  async setActiveDrive(context, payload) {
    await context.commit('SET_ACTIVE_DRIVE', payload);
  }, 

  async storeUser(context, payload = { service: '', userData: '' }) {
    const { service, userData } = payload;

    if (!userData)
      return;

    // check array of users if there's a match, exit if already stored
    if (context.state.drives[service] && context.state.drives[service].drives) {
      for (let i = 0; i < context.state.drives[service].drives.length; i++) {
        console.dir(context.state.drives[service].drives[i].id);
        console.log('++');
        if (context.state.drives[service].drives[i].id === userData.id) {
          userData['isSignedIn'] = true;
          userData['isActive'] = true;
          context.state.drives[service].drives[i] = userData;
          return;
        }
      }
    }

    context.commit('SET_USER', { service: service, user: { ...userData, isSignedIn:  true, isActive: true } });
    //context.dispatch('getDrive', { path: '', service: service, prefix: '' });
    context.commit('SET_LOGIN_STATUS', true);
  },

  //-------------------------------------------------------------------
  // Drivey Signin
  //-------------------------------------------------------------------
  /*async driveySignin(context, payload = {email: '', password: '', auth_token: '', provider_id: '', account_id: ''}) {
    const { auth_token, provider_id, account_id, email, password} = payload
    //store.commit('IS_LOADING', true);
    var response = await fetch(`${baseUrl}/api/v1/user/login?auth_token=${auth_token}&provider_id=${provider_id}&account_id=${account_id}${email ? '&email=' + email : ''}${password ? '&password=' + password : ''}`, settings)
    // if(!response.ok) {
    //     throw Error('There was an error logging in')
    // }
    var responseBody = await response.json()
    if(!responseBody || responseBody.error) {
      //store.commit('IS_LOADING', false);
        throw Error('There was an error logging in')
    }
    //if(responseBody && !responseBody.error) store.commit('IS_LOADING', false);
    return responseBody
  },*/

  //-------------------------------------------------------------------
  // Signup
  //-------------------------------------------------------------------
  async signup(context, payload = { email: '', password: '', name: '', terms: false, marketing: false }) {
    let userData;
    store.commit('IS_LOADING', true);
      Drivey.signup(payload).then(async (res) => {
        if(res.code && res.code == 'Set Error') {
          context.commit('SET_TOAST', { message: res.message, position: 'top-right', type: 'error', duration: 5000 });
          throw Error(res.message)
        } else if(res && res.access_token) {
          userData = { 
            accessToken: res.access_token,
            redirect: '/drivey',
            service: 'drivey',
          };
          console.dir(res);
          console.dir(userData);
          context.dispatch("driveyLogin", res.access_token).then(async () => {
            await localStorage.setItem('userID', res.user.id);
            await context.dispatch("storeUser", {service: 'drivey', userData: userData});
            await store.dispatch('loginUserdata', { service: 'drivey' }).then(async () => {
              window.location = '/drivey';
            });           
            //});
          });
        } else {
          return res;
        }
      }).catch(err => {
        //throw Error(err);
        console.dir(err);
      });
      /*let response = await fetch(`${baseUrl}/api/v1/user/signup?email=${email}&password=${password}&name=${name}`, settings)
      if(!response.ok) {
          throw Error('There was an error signing up.')
      }
      let responseBody = await response.json()
      if(!responseBody || responseBody.error) {
        store.commit('IS_LOADING', false);  
        throw Error('There was an error signing up.')          
      }
      store.commit('IS_LOADING', false);
      return responseBody*/
  },
  async ssoSignupService(context, payload = { service: '' }) {
    const { service } = payload;
    
    Drivey.ssoLogin({service:service, type:2}).then(resp => {
      return resp;
    });
  }, 

  //-------------------------------------------------------------------
  // Drivey Logout
  //-------------------------------------------------------------------
  async driveyLogout(context) {
    await TokenService.removeToken();
    await context.commit('SET_LOGIN_STATUS', false);
    window.location = '/';
  },

  //-------------------------------------------------------------------
  // Drivey Password
  //-------------------------------------------------------------------
  async driveyPass(context, payload = { email:'' }) {
    const { email } = payload;
    store.commit('IS_LOADING', true);
    console.dir(email);
    let response = await fetch(`${baseUrl}/api/v1/user/forgot_pass?email=${email}`, settings)
    if(!response.ok) {
        store.commit('IS_LOADING', false);
        throw Error('There was an error, please try again later.')
    } else if(response.code && response.code == 'Set Error') {
      context.commit('SET_TOAST', { message: response.message, position: 'top-right', type: 'error', duration: 5000 });
      throw Error(response.message)
    } else {
      let responseBody = await response.json()
      if(!responseBody || responseBody.error) {
          store.commit('IS_LOADING', false);
          throw Error('There was an error, please try again later.')
      }
      if(responseBody && !responseBody.error) store.commit('IS_LOADING', false);
      return responseBody
    }
  },

  async driveyReset(context, payload = { token:'', password:'', confirm:'' }) {
    const { token, password, confirm } = payload;
      store.commit('IS_LOADING', true);
      let response = await fetch(`${baseUrl}/api/v1/user/reset_pass?token=${token}&password=${password}&password_repeat=${confirm}`, settings)
      if(!response.ok) {
          store.commit('IS_LOADING', false);
          throw Error('There was an error resetting your password.')
      } else if(response.code && response.code == 'Set Error') {
        context.commit('SET_TOAST', { message: response.message, position: 'top-right', type: 'error', duration: 5000 });
        throw Error(response.message)
      } else {
        let responseBody = await response.json()
        if(!responseBody || responseBody.error) {
            store.commit('IS_LOADING', false);
            throw Error('There was an error resetting your password.')
        }
        if(responseBody && !responseBody.error) store.commit('IS_LOADING', false);
        return responseBody
      }
  },

  //-------------------------------------------------------------------
  // Drivey Add Account
  //-------------------------------------------------------------------
  /*async driveyAddAccount(context, payload = {auth_token: '', provider_id: '', account_id: ''}) {
    const { auth_token, provider_id, account_id } = payload;
    store.commit('IS_LOADING', true);
    let response = await fetch(`${baseUrl}/api/v1/user/create?auth_token=${auth_token}&provider_id=${provider_id}&account_id=${account_id}`, settings)
    if(!response.ok) {
      throw Error('There was an error signing up.')
    } else if(response.code && response.code == 'Set Error') {
      context.commit('SET_TOAST', { message: response.message, position: 'top-right', type: 'error', duration: 5000 });
      throw Error(response.message)
    } else {
      let responseBody = await response.json()
      // if(!responseBody || responseBody.error) {
      //     throw Error('There was an error signing up.')
      // }
      store.commit('IS_LOADING', false);
      return responseBody
    }
  },*/

  //-------------------------------------------------------------------
  // Billing Add Account
  //-------------------------------------------------------------------
  async billingCreate(context, payload = {tier_id: '', card_id: '', token: ''}) {
    const { tier_id, card_id, token } = payload;

    let settingsBillings = { headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/json',
        'Authorization': `Bearer ${token}`,
      },
      method: 'POST',
    }

    let url = '';
    if(card_id){
      url = `${baseUrl}/api/v1/billing/memberships/create?tier_id=${tier_id}&card_id=${card_id}`
    } else {
      url = `${baseUrl}/api/v1/billing/memberships/create?tier_id=${tier_id}`
    }
    store.commit('IS_LOADING', true);
    let response = await fetch(url, settingsBillings)
    if(!response.ok) {
      throw Error('There was an error signing up.')
    } else if(response.code && response.code == 'Set Error') {
      context.commit('SET_TOAST', { message: response.message, position: 'top-right', type: 'error', duration: 5000 });
      throw Error(response.message)
    } else {
      let responseBody = await response.json()
      // if(!responseBody || responseBody.error) {
      //     throw Error('There was an error signing up.')
      // }
      store.commit('IS_LOADING', false);
      return responseBody
    }
  },

  //-------------------------------------------------------------------
  // Logout User
  //-------------------------------------------------------------------
  /*async logout(drive) {
    try {
      switch (drive) {
        case 'ONEDRIVE': case 'DROPBOX': case 'DROPBOXBUSINESS': case 'ICLOUD':
          break;
        case 'GOOGLEDRIVE':
          console.log('google d')
          await googleAuth.signOut();
          break;
        default:
          throw Error(`invalid drive name, ${drive}`);
      }
    } catch (err) {
      console.error(`[logout]: `, err);
    }
    //TokenService.removeToken();
    //TokenService.removeRefreshToken();
    //commit(LOGOUT);
  },*/

}

//===============================================================================
// Mutations
//===============================================================================
const mutations = {
  /**
   * @description Insert new user data to the front of specified drive array
   * @param {object} state
   * @param {object} payload
   * @param {string} payload.drive
   * @param {object} payload.user
   */
  'SET_USER'(state, payload) {
    state.drives[payload.service].unshift(payload.user);
    console.log('a message', payload);
  },
  'SET_LOGIN_STATUS'(state, payload) {
    state.isLoggedIn = payload;
    //if(!state.isLoggedIn) this.driveyLogout();
  },
  'SET_CLOSE_MODAL'(state, payload) {
    state.isModalClosed = payload;
  },
  'SET_SERVICE'(state, payload) {
    if(payload) state.service = payload.service
  }, 
  'SET_ACTIVE_DRIVE'(state, payload) {
    state.activeDrive = payload
  }, 

  //-------------------------------------------------------------------
  // Reset State
  //-------------------------------------------------------------------
  [RESET_STATE](state) {
    state = {
      drives: {

      }
    }
    Object.keys(initialState).forEach(key => { state[key] = initialState[key] })
  }
}

//===============================================================================
// Getters
//===============================================================================
const getters = {
  /*drives: state => service => {
    return (service && typeof service === 'string') ? state.drives[service] : state.drives;
  },*/
  loggedInDrives: state => {
    var drives = [];
    if(state.drives.onedrive.drives.length > 0) drives.push('OneDrive');
    if(state.drives.google.drives.length > 0) drives.push('Google Drive');
    if(state.drives.dropbox.drives.length > 0) drives.push('Dropbox Personal');
    if(state.drives.dropboxbusiness.drives.length > 0) drives.push('Dropbox Business');
    if(state.drives.icloud.drives.length > 0) drives.push('ICloud');
    if(state.drives.aws.drives.length > 0) drives.push('Amazon AWS');
    // if(state.users.BOX.length > 0) drives.push('Box');

    return drives;
  },
  isLoggedIn: state => state.isLoggedIn,
  isModalClosed: state => state.isModalClosed, 
  service: state => state.service, 
  activeDrive: state => state.activeDrive, 
}

export default {
  state,
  actions,
  mutations,
  getters
};

