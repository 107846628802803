const TOKEN_KEY = 'token'

const TokenService = {
  getToken() {
      return (process.browser) ? localStorage.getItem(TOKEN_KEY) : null
  },

  saveToken(accessToken) {
      console.dir(TOKEN_KEY);
      console.dir(accessToken);
      if(process.browser) localStorage.setItem(TOKEN_KEY, accessToken)
  },

  removeToken() {
      if(process.browser) {
        localStorage.removeItem(TOKEN_KEY)
        localStorage.removeItem('vuex')
      }
  }
}

const storageService = {
    getItem(name) {
        return localStorage.getItem(name)
    },

    setItem(name, value) {
        localStorage.setItem(name, value)
    },

    removeItem(name) {
        localStorage.removeItem(name)
    },
}

export { TokenService, storageService }
