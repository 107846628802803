//===============================================================================
// Imports
//===============================================================================
import { RESET_STATE } from '../mutations.type'
import billing from '@/services/Drivey/billing'

//===============================================================================
// Initial State
//===============================================================================
const initialState = {
  listTiers: [],
  listOptions: [],
  listInvoices: []
}

const state = { ...initialState };


//===============================================================================
// Actions
//===============================================================================
const actions = {
  async billingDashboard(context) {
    return new Promise((resolve, reject) => {
      billing.billingDashboard().then(res => {
        context.commit('GET_MEMBERSHIP', res.data.membership)
        context.commit('GET_OPTIONS', res.data.options)
        resolve(res.data)
      }).catch(err => {
        reject(err)
      })
    })
  },
  async getTiers(context) {
    await billing.getTiers().then(res => {
      context.commit('GET_TIERS', res.data)
    });
  },
  async cardsCreate(context, payload = {token: ''}) {
    const { token } = payload;
    context.commit('SETTINGS_CARD_CLOSE_MODAL', false);

    return new Promise((resolve, reject) => {
      billing.cardsCreate(token).then(res => {
        context.commit('SETTINGS_CARD_CLOSE_MODAL', true);
        resolve(res.data)
      }).catch(err => {
        reject(err)
      })
    })
  },
  async cardsRemove(context, payload = {card_id: ''}) {
    const { card_id } = payload;
    context.commit('SETTINGS_CARD_CLOSE_MODAL', false);

    return new Promise((resolve, reject) => {
      billing.cardsRemove(card_id).then(res => {
        context.commit('SETTINGS_CARD_CLOSE_MODAL', true);
        resolve(res.data)
      }).catch(err => {
        reject(err)
      })
    })
  },
  async cardsReplace(context, payload = {card_id: ''}) {
    const { card_id } = payload;
    context.commit('SETTINGS_CARD_CLOSE_MODAL', false);

    return new Promise((resolve, reject) => {
      billing.cardsReplace(card_id).then(res => {
        context.commit('SETTINGS_CARD_CLOSE_MODAL', true);
        resolve(res.data)
      }).catch(err => {
        reject(err)
      })
    })
  },
  async membershipChange(context, payload = {tier_id: ''}) {
    const { tier_id } = payload;
    return new Promise((resolve, reject) => {
      billing.membershipChange(tier_id).then(res => {
        resolve(res.data)
      }).catch(err => {
        reject(err)
      })
    })
  },
  async membershipOptionsAdd(context, payload = {id: '', qty: ''}) {
    const { id, qty } = payload;
    return new Promise((resolve, reject) => {
      billing.membershipOptionsAdd(id, qty).then(res => {
        context.dispatch('getTracking', {});
        resolve(res.data)
      }).catch(err => {
        reject(err)
      })
    })
  },
  async membershipOptionsUpdate(context, payload = {id: '', qty: ''}) {
    const { id, qty } = payload;
    return new Promise((resolve, reject) => {
      billing.membershipOptionsUpdate(id, qty).then(res => {
        context.dispatch('getTracking', {});
        resolve(res.data)
      }).catch(err => {
        reject(err)
      })
    })
  },
  async getCards(context) {
    await billing.cardsUser().then(res => {
      context.commit('GET_CARDS', res.data)
    })
  },
  async getInvoices(context) {
    await billing.invoicesUser().then(res => {
      context.commit('GET_INVOICES', res.data)
    })
  },
  async getOptions(context) {
    return new Promise((resolve, reject) => {
      billing.membershipOptionsAll().then(res => {
        context.commit('GET_OPTIONS', res.data)
        resolve(res.data)
      }).catch(err => {
        reject(err)
      })
    })
  }
}

//===============================================================================
// Mutations
//===============================================================================
const mutations = {
  //-------------------------------------------------------------------
  // Get Tiers
  //-------------------------------------------------------------------
  'GET_TIERS'(state, listTiers) {
    state.listTiers = listTiers
  },
  //-------------------------------------------------------------------
  // Get Cards
  //-------------------------------------------------------------------
  'GET_CARDS'(state, listCards) {
    state.listCards = listCards
  },
  //-------------------------------------------------------------------
  // Get Invoices
  //-------------------------------------------------------------------
  'GET_INVOICES'(state, listInvoices) {
    state.listInvoices = listInvoices
  },
  //-------------------------------------------------------------------
  // Get Options
  //-------------------------------------------------------------------
  'GET_OPTIONS'(state, listOptions) {
    state.listOptions = listOptions
  },
  'GET_MEMBERSHIP'(state, membership) {
    state.membership = membership
  },
  'SETTINGS_CARD_CLOSE_MODAL'(state, payload) {
    state.settingsCardModalClosed = payload;
  },
  //-------------------------------------------------------------------
  // Reset State
  //-------------------------------------------------------------------
  [RESET_STATE](state) {
    state = {
      drives : {

      }
    }
    Object.keys(initialState).forEach(key => { state[key] = initialState[key] })
  }
}

//===============================================================================
// Getters
//===============================================================================
const getters = {
  listTiers: state => state.listTiers,
  listCards: state => state.listCards,
  listOptions: state => state.listOptions,
  membership: state => state.membership,
  listInvoices: state => state.listInvoices,
  settingsCardModalClosed: state => state.settingsCardModalClosed
}

export default {
  state,
  actions,
  mutations,
  getters
};
