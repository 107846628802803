import generatedRoutes from './generated';
import store from "@/store";
import { toggleSidebar } from '/src/components/layout/sidebar/state';

const allRoutes = [
  {
    path: '/',
    component: () => import('../views/Drivey.vue'),
    redirect: '/drivey', 
  },
  {
    path: '/access-denied',
    component: () => import('../views/access-denied.vue')
  },
  /*{
    path: '/search',
    component: () => import('views/search')
  },
  {
    path: '/redirect/:id',
    component: () => import('views/redirect')
  },*/
].concat(
  generatedRoutes,
  { path: '*', component: () => import('../views/error404.vue') }
  );

  export default [
    {
      path: '/',
      component: () => import('./../components/layout/index'),
      children: allRoutes,
      beforeEnter: () => {
        // console.log( 'store state', store.state.authModule);
        if(!store.state.authModule.isLoggedIn) {
          return '/login'
        }  
        toggleSidebar(false);
      }
    },
    {
      path: '/login',
      component: () => import('../views/Login.vue'),
      meta: {
        service: 'login'
      }
    },
    {
      path: '/signup',
      component: () => import('../views/Signup.vue'),
      meta: {
        service: 'signup'
      }
    },
    {
      path: '/password',
      component: () => import('../views/Password.vue'),
      meta: {
        service: 'password'
      }
    },
    {
      path: '/oauth:catchAll(.*)',
      beforeEnter: async () => {
        const location = window.location;
        const urlParams = new URLSearchParams(location.search);

        let service;
        let token;
        let redirect = (service) ? '/'+service : '/';

        service = urlParams.get('service');
        token = urlParams.get('token');
        
        toggleSidebar(false);

        /*if(service === 'google'){
          drive = 'GOOGLEDRIVE';
          redirect = '/googledrive';          

        } else if(service === 'onedrive'){
          drive = 'ONEDRIVE';
          redirect = '/onedrive';

        } else if(service === 'dropbox'){
          drive = 'DROPBOX';
          redirect = '/dropbox';

        } else if(service === 'dropboxbusiness'){
          drive = 'DROPBOXBUSINESS';
          redirect = '/dropboxbusiness';

        } else if(service === 'box'){
          drive = 'BOX';
          redirect = '/box';

        } else {
          redirect = '/';
        }*/

        const logData = async (bearerToken) => {
          var accessToken = bearerToken !== '' ? bearerToken : await localStorage.getItem('token');
          await store.dispatch('loginUserdata', {service: service}).then(async (res) => {
            if(res === false) {
              window.opener.location = '/';
              window.close();
            }
            console.log(redirect);
            console.log(accessToken);
            await store.dispatch('billingCreate', {tier_id: 1, card_id: '', token: accessToken}).then(() => {
              window.opener.location = redirect;
              window.close();
            });
          });
        };

        await store.dispatch('loginConnect', {service: service, token: token, refresh: urlParams.get('refresh'), account_id: urlParams.get('account_id'), code: urlParams.get('code')}).then(async (res) => {
          if(res === false) {
            window.location = '/';
          }
          console.dir(res);

          if(res.message === "User has reached maximum limit for drives. Please upgrade to add more drives"){
            window.location = '/settings?tab=plan&upgrade=true';
          }
          if(res.code == "Set Error") {
            console.log(res.message);
          } else {
            var access_token = '';
            if(res.message !== "Cannot add duplicate drive. Drive has already been added to Drivey"){
              await localStorage.setItem('token', res.user.access_token);
              access_token = await res.user.access_token;
            }
            await logData(access_token);
          }
        });

      },
      meta: {
        service: 'auth'
      }
    }
];
