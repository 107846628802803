//===============================================================================
// Imports
//===============================================================================
import { 
  RESET_STATE
} from '../mutations.type'

//===============================================================================
// Initial State
//===============================================================================
const initialState = {
  filter: {
    show: false,
    keywords: [],
    drives: {
      onedrive: false,
      google: false,
      dropbox: false,
      dropboxbusiness: false,
      icloud: false
    },
    fileTypes: {
      document: false,
      sheet: false,
      audio: false,
      video: false,
      folder: false
    }
  }
};

const state = initialState;

//===============================================================================
// Actions
//===============================================================================
const actions = {
  toggleFilter({ state }) {
    state.filter.show = !state.filter.show;
    //console.log(state.filter.show)
  }
}

//===============================================================================
// Mutations
//===============================================================================
const mutations = {
  //-------------------------------------------------------------------
  // Reset State
  //-------------------------------------------------------------------
  [RESET_STATE]() {
    Object.keys(initialState).forEach(key => { state[key] = initialState[key] })
  }
}

//===============================================================================
// Getters
//===============================================================================
const getters = {
  filterShown: state => state.filter.show
}

export default {
  state,
  actions,
  mutations,
  getters
};
